export const handleStatus = (element: any) => {
  let status = element === "Approved" ? true : false;
  // console.log(element,status,"status");


  //   <div className="school-status">

  //   <div className="complete">
  //     <span>Approved </span>
  //   </div>
  //   </div>
  // ) : (
  //   <div className="school-status">

  //   <div className="in-complete">
  //     <span>Pending Approval </span>
  //   </div>
  //   </div>

  return status ? (
    <div className="complete">
      <span>Approved </span>
    </div>
  ) : (
    <div className="in-complete">
      <span>Pending Approval </span>
    </div>
  );
};

export const viewServiceProviderStatus = (element: any) => {

  return (
    <>
      <div className="school-status">
        {element === "Approved" ? (
          <div className="complete">
            <span>Approved</span>
          </div>
        ) : (
          <div className="in-complete">
            <span>Pending Approval</span>
          </div>
        )}
      </div>
    </>
  );
};

export const checkTableNull = (el: any) => {
  return el ? el : "-";
};
export const checkNullToString = (el: any) => {
  return el ? el : ""
}
export const checkNullToNumber = (el: any) => {
  return el ? el : 0
}
export const initAddEditValue = {
  name: "",
  fullDescription: "",
  shortDescription: "",
  curricula: [],
  priorities: "",
  yearLaunched: "",
  userBase: "",
  ageGroups: "",
  logo: "",
  images: [],
  video: [],
  languages: [],
  supportedPlatform: "",
  caseStudy: null,
  testimonials: null,
};


export const columnsFilterValues = (el: any) => {
  // console.log("columnsFilterValues", columnsFilterValues); 

  let arrayResult: any = []
  el && el.forEach((item: any) => {
    // arrayResult
    let cacheObject = {
      text: item,
      value: item,
    }
    arrayResult.push(cacheObject)
    // console.log(cacheObject);

  })

  return arrayResult
}

// <div className="school-status">
//     <div className="complete"><span>{element}</span></div>
//     {/* <div className="in-complete"><span>Pending</span></div> */}
// </div>
