import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, notification, message, Space, Spin, Table } from 'antd';
import EndpointService from '../../utilities/constants/endpointService';
import { cypherService } from '../../utilities/cypher/cypherService';
import HTTPService from '../../service/HTTPService';
import React, { useEffect, useState } from 'react'
import { checkNullToString, checkTableNull } from '../viewSchool/helper';
import { useNavigate } from 'react-router-dom';
import { handleRedirect, locationSplit, prioritiesPillarName } from './edTechTableHelper';
import { baseUrl } from '../../environments/environment';

import { checkNullToNumber, columnsFilterValues, handleStatus } from "../viewSchool/./helper";

export default function EdtechExpert() {
    const navigate = useNavigate();
    cypherService.setSessionStorage("detailsTab", "3");
    const [loader, setLoader] = useState<boolean>(false);
    const [tableLists, setTableLists] = useState<any>([])
    const [tableFilters, setTableFilters] = useState<any>([])
    useEffect(() => {
        getEdTechDetails();
        tableFilter()
    }, [])

    const tableFilter = () => {
        const mapUrl = EndpointService.filterMasterRecord;
        HTTPService.get(mapUrl, {})
            .then((response) => {
                setTableFilters(response.data)
            }).catch((err) => {
                setLoader(false);
                notification.error({
                    message: "",
                    // description: err.response.data.status.message,
                    placement: "top",
                });
            });
    }

    const areaofexpertiseSplit = (el: any,filters:any) => {
        console.log(el,filters);
        let arrayList: any = []
        el.map((elm: any, index: any) => {
            if (elm) {
                let obj = {
                    label: elm.otherText,
                    key: index,
                }
                arrayList.push(obj)
            }
        })
        console.log(arrayList);

        if (arrayList.length > 1) {
            return <Dropdown className='tbl-location' menu={{ items: arrayList }} trigger={['click']}>
                <a onClick={e => e.preventDefault()}>
                    <Space> Multi Location</Space>
                </a>
            </Dropdown>
        } else {
            return <span className='tbl-location'>{el.map((elm: any) => (
                <span>{elm.otherText}</span>
            ))}</span>
        }
    }
    const locationSplit = (el: any,filters:any) => {
        console.log(el,filters);
        let arrayList: any = []
        el.map((elm: any, index: any) => {
            if (elm) {
                let obj = {
                    label: elm.otherText,
                    key: index,
                }
                arrayList.push(obj)
            }
        })
        console.log(arrayList);

        if (arrayList.length > 1) {
            return <Dropdown className='tbl-location' menu={{ items: arrayList }} trigger={['click']}>
                <a onClick={e => e.preventDefault()}>
                    <Space> Multi Location</Space>
                </a>
            </Dropdown>
        } else {
            return <span className='tbl-location'>{el.map((elm: any) => (
                <span>{elm.otherText}</span>
            ))}</span>
        }
    }

    const getEdTechDetails = () => {
        setLoader(true)
        let mapUrl = EndpointService.edtechExpert + `range?limit=20&start=0`;
        HTTPService.get(mapUrl).then((response: any) => {
            let data: any = response.data
            assignValueToColumns(data)
            message.success(response.data.status.message);
            setLoader(false)
        }).catch((error) => {
            setLoader(false);

        })
    }


    const assignValueToColumns = (el: any) => {
        let cacheTableDataList: any = [];
        el.forEach((element: any) => {
            let cacheObject: any = {};
            cacheObject["name"] = checkNullToString(element.name);
            cacheObject["email"] = checkNullToString(element.email);
            cacheObject["phoneNumber"] = checkNullToString(element.phoneNumber);
            cacheObject["yearsOfExperience"] = checkNullToString(element.yearsOfExperience);
            cacheObject["areasOfExpertise"] = checkNullToString(element.edTechExpertProductCategory);
            cacheObject["location"] = checkNullToString(element.edTechExpertQuestionaire);
            cacheObject["edTechExpertId"] = checkNullToString(element.edTechExpertId);
            cacheTableDataList.push(cacheObject);
        });

        setTableLists(cacheTableDataList)
    }
    const addServiceProvider = () => {
        navigate("/edtechExpertDetails");
        cypherService.setLocalStorage("isEditEdTech", false);
    };
    const filterDataValue = (el: any) => {
        console.log(el)

        let arrayList: any = []
        el.map((elm: any, index: any) => {
            arrayList.push(elm.otherText)
        })
        debugger
        return arrayList.toString()
    }
 

    const sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
    const EdTechTableColumns: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: 150,
            key:"name",
            sorter: (a: any, b: any) => sorter(a.name, b.name),
            render: (element: any, record: any) => <span className="hoverElement" onClick={() => { handleRedirect("isEdit", record, navigate) }}>{checkTableNull(element)}</span>,
        },
        {
            title: 'Mail id',
            dataIndex: 'email',
            key:"email",
            width: 200,
            sorter: (a: any, b: any) => sorter(a.email, b.email),
        },
        {
            title: 'Areas of Expertise',
            dataIndex: 'areasOfExpertise',
            key:"areasOfExpertise",
           
            sorter: (a: any, b: any) => sorter(a.areasOfExpertise, b.areasOfExpertise),
            render: (e: any) => (prioritiesPillarName(e))

            //   filters: [...columnsFilterValues(tableFilters.areaOfExpertise)],
            // onFilter: (value: string, record: any) => prioritiesPillarName(record.areasOfExpertise).indexOf(value) === 0,
            // render: (e: any, record: any) => (
            //     <>{prioritiesPillarName(e)} </>
            // )    
        },
        {
            title: 'Experience',
            dataIndex: 'yearsOfExperience',
            key:"yearsOfExperience",
            sorter: (a: any, b: any) => sorter(a.yearsOfExperience, b.yearsOfExperience),
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
        },

        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            filters: [...columnsFilterValues(tableFilters.locations)],
            onFilter: (value: string, record: any) => filterDataValue(record.location).indexOf(value) === 0,
            render: (e: any, record: any) => (
                <>{locationSplit(e, record)} </>
            )
        },

    ];

    const downloadRequest = () => {
        let url = baseUrl + EndpointService.getDownloadEdTech;
        window.open(url, "_blank");
    };

    return (
        <div className="view-table school-list">
            {loader ? <Spin /> : null}

            <div className="view-table-sorting">
                <div className="main view-table-button">
                    <Button type="primary" onClick={downloadRequest}>
                        <DownloadOutlined /> Download
                    </Button>
                </div>
                <div className="main view-table-button">
                    <Button
                        type="primary"
                        onClick={() => {
                            addServiceProvider();
                        }}
                    >
                        <PlusOutlined /> Add EdTech Expert
                    </Button>
                </div>
            </div>
            <Table
                className="viewSchool-table edtechTable"
                columns={EdTechTableColumns}
                dataSource={tableLists}
                scroll={{ y: "calc(70vh - 80,px)" }}

            />
        </div>
    )
}
