
import { Tabs } from 'antd';
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons'
import RequestsTable from './CampaignsTable'
import Header from '../header/Header';

const { TabPane } = Tabs;

const Campaigns = () => {

    return (
        <div className="dashboard viewData-main">
            <Header></Header>
            <div className="viewData request-tab">
            <Tabs defaultActiveKey="1">
                <TabPane tab="Requests" key="1">
                   <RequestsTable />
                </TabPane>
            </Tabs>
            </div>
        </div>
    )
}
export default Campaigns
