export default class EndpointService {
  public static loginUrl = "digital-master/user/login";
  public static createPasswordUrl = "digital-master/user/set-password";
  public static resendOtpUrl = "digital-master/party/resend-otp";
  public static createCustomerUrl = "digital-master/party/createPartyFromAdmin";
  public static forgotPwdUrl = "digital-master/user/forgot-password";
  public static forgotPwdValidateUrl = "digital-master/user/forgot-password/otpvalidation";
  public static getCustomerAssessmentUrl = "digital-master/assessment/";
  public static getCurriculumListIUrl = "digital-master/curriculum/list";
  public static addAdmin = "digital-master/user/signup/";
  public static getSchoolData = "digital-master/user/customer/range";
  public static getSchoolDetails = "digital-master/user/customer/";
  public static customerDashboardUrl = "digital-dashboard/customerdashboard/";
  public static getServiceProviderData = "digital-master/user/serviceprovider/range";
  public static getSingleProductDetails = "digital-master/user/serviceprovider/product-details/";
  public static getSingleServiceProviderDetails = "digital-master/user/serviceprovider-details/";
  public static getPriorities = "digital-master/serviceProvider/product/";
  public static deleteTestimonialUrl = "digital-master/serviceProvider/product/product_testimonial/";
  public static getAgeGroupListUrl = "digital-master/agegroup/list";
  public static getProductCategoryOneOfTheAssessmentQuestionUrl = "digital-master/assessment/product-category";
  public static deleteCustomerUrl = "digital-master/party/";
  public static deleteServiceProviderCertificate = "digital-master/party/service-provider/certificate/";
  public static editCustomerUrl = "digital-master/party/";
  public static sendEmailUrl = "digital-master/party/send-setpassword-mail";
  public static getFilterParamUrl = "digital-master/user/filter-by";
  public static getEmailCampaignListUrl = "digital-master/user/serviceprovider/email-campaign/range";
  public static emailCamignStatusChangeUrl = "digital-master/user/serviceprovider/email-campaign/";
  public static getMeetingListUrl = "digital-master/user/meeting-records";
  public static getCampaignCustomerListUrl = "digital-master/user/email-campaign/";
  public static getCampignTemplatePreviewUrl = "digital-master/user/serviceprovider/email-campaign-template/";
  public static getMeetingVideoPreviewUrl = "digital-dashboard/customerdashboard/file-previewlink/";
  public static getDownloadMeetingUrl = "digital-dashboard/serviceproviderdashboard/file-download/";
  public static getProductInfoUrl = "digital-master/serviceProvider/product/";
  public static saveProductUrl = "digital-master/serviceProvider/product/productdetail";
  public static updateProductUrl = "digital-master/serviceProvider/product";
  public static deleteProductUrl = "digital-master/user/product/";
  public static downloadServiceProviderUrl = "digital-master/user/download-service-provider-records";
  public static downloadSchoolUrl = "digital-master/user/download-customer-records";
  public static deleteMultiPartFileUrl = "/digital-master/serviceProvider/product/productdetail/";
  public static productSpotlight = "/digital-master/user/service-provider-product-spotlight/";
  public static filterMasterRecord = "/digital-master/user/filter-master-records";
  public static edtechExpert = "/digital-master/user/edtech-expert/";
  public static createEdtechExpert = "/digital-master/user/edtech-expert?";
  public static getSingleEdtechExpert = "/digital-master/user/edtech-expert-details/";
  public static getDownloadEdTech = "digital-master/user/download-edtech-expert-records";
  public static saveCredits = "/digital-dashboard/edtechexpert/credit";
  public static createMeetingApproved = "/digital-dashboard/edtechexpert/edtech-expert-meeting-approval";
  public static getNotificationList = "/digital-dashboard/notification/"

  public static getMasterRecords = 'digital-dashboard/messaging/master-records';
  public static sendMessage = '/digital-dashboard/messaging/?messagingNotificationDtoStr=';
  public static getConversationsRange = '/digital-dashboard/messaging/range?';
  public static getSingleConversationList = '/digital-dashboard/messaging/detail-range';
  public static getConversationStart = '/digital-dashboard/messaging/detail?messagingNotificationDetailDtoString=';
  public static setNotificationasRead = '/digital-dashboard/notification/isViewed'


  // https://dgp-admin.cloudnowtech.com/core/api/v1/digital-master/serviceProvider/productdetails
  // "https://dgp-admin.cloudnowtech.com/core/api/v1/digital-master/serviceProvider/productinfo
  // https://dgp-admin.cloudnowtech.com/core/api/v1/digital-master/serviceProvider/product/298/category
  //digital-master/serviceProvider/product//1488/category
}
