import * as Yup from "yup";

let bussinessEmail = new RegExp("^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$");

export const AddSchoolValidationSchema = Yup.object({
  personName: Yup.string()
    .required("Please enter a name")
    .matches(/^([A-Za-z\s]*)$/gi, "Please enter a valid person name"),
  roles: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string()).required("Please select at least one role")),
  emailId: Yup.string().required("Please enter email id").email("Invalid Email").matches(bussinessEmail, "Please enter work email"),
  curricula: Yup.array().min(1, "Atleast one must be choose").required("Please select the curriculum"),
  grades: Yup.array().min(1, "Atleast one must be choose").required("Please select the grade"),
  emirate: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string()).required("Please select emirate")),
  schoolCategory: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string()).required("Please select any one")),
  name: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().min(1, "Please choose option").nullable() : Yup.string().required("Please select any one"))),
  schoolName: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string()).required("Please enter School or Organisation name")),
  priority: Yup.array().required("Please choose priority ").min(1, "Atleast one must be choose").nullable(),
  studentCount: Yup.string().required("Student count field is required").matches(/^\d+$/, "Please enter a valid student count"),
  priorityDescription: Yup.string().nullable(),
});

export interface AddSchoolInitialValues {
  personName: string;
  roles: any;
  emailId: string;
  curricula: any;
  grades: any;
  emirate: any;
  schoolCategory: any;
  studentCount: string;
  priority: any;
  priorityDescription: string;
  schoolName: any;
  name: any;
}

export interface statusType {
  details: string;
  status: string;
}
