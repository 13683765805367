import { Table, Input, Spin, notification, Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import EndpointService from '../../utilities/constants/endpointService';
import HTTPService from '../../service/HTTPService';
import moment from 'moment';
import environment from '../../environments/environment';
import { checkTableNull } from '../viewSchool/helper';
import { Pagination } from 'antd';
import { CheckOutlined, CheckSquareOutlined, CloseOutlined, CloseSquareOutlined, VideoCameraAddOutlined } from '@ant-design/icons'
const PreviousMeetings = (props: any) => {


    const [previousMeetingList, setPreviousMeetingList] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [start, setStart] = useState<any>(0);
    const [limit] = useState<any>(10);
    const [searchText, setSearchText] = useState<string>('');
    const [totalData, setTotalData] = useState<any>(0);
    let {currentTab} = props;

    useEffect(() => {
        getPreviousMeetingList(start, limit, searchText);
    }, [currentTab])

    const getPreviousMeetingList = (startValue: any, limitValue: any, searchTextValue: string) => {
        if (currentTab == '2') {
            setLoader(true);
            let mapUrl: string = EndpointService.getMeetingListUrl + `?limit=${limitValue}&meetingRecordFlag=Previous&start=${startValue}`;
            if (searchText != '') {
                mapUrl = mapUrl + `&searchBy=${searchTextValue}`;
            }
            HTTPService.get(mapUrl, {}).then((response) => {
                if (response.status.success.toLowerCase() == 'success') {
                   
                    let apiResponse: any = response.data ? response.data : []
                    // let meetingData = apiResponse && apiResponse[0]
                    // // const { customerMeetingScheduleRecordsDtoList, edTechExpertMeetingScheduleDtoList } = meetingData && meetingData
                    let tempPreviousMeetingList: any = [];
                    // apiResponse && apiResponse.forEach((item: any) => {
                    //     let meetingObject = {
                    //         name: item.serviceProviderId ? item.serviceProviderId.companyName: item.customer.name,
                    //         // nameList : [item.serviceProviderName, "SolvEd Admin"],
                    //         organization: item.organizationName,
                    //         dateTime: moment(item.scheduleDate).format('LLL'),
                    //         subject: item.scheduleNote,
                    //         meetingLink: item.meetingUrl,
                    //         action: item.meetingRecordUrl
                    //     }
                    //     tempPreviousMeetingList.push(meetingObject)
                    // })
                    console.log(apiResponse);
                    
                    apiResponse&&apiResponse.map((item: any) => {
debugger;
                        let user: string = ""
                        let typeName: string = ""
                        let isActive: boolean = false
                        if (item.customer) {
                            user = item.customer.name
                            typeName = "customer"
                            isActive = true
                        } else {
                            user = item.serviceProvider.companyName
                            typeName = "serviceProvider"
                            isActive = false
                        }
                        let meetingObject = {
                            name: user,
                            organization: item.organizationName ? item.organizationName:"-",
                            dateTime: moment(item.scheduleDate).format('LLL'),
                            nameList : [item.edTechExpert.name, "SolvEd Admin"],
                            subject: item.scheduleNote ? item.scheduleNote:"-",
                            meetingLink: item.meetingUrl ? item.meetingUrl:"-",
                            action: item.meetingRecordUrl ? item.meetingRecordUrl:"-",
                            status: item.status ? item.status:null,
                            type: typeName
                        }
                        tempPreviousMeetingList.push(meetingObject)
                    })

                    setPreviousMeetingList([...tempPreviousMeetingList]);
                    // setTotalData(response.totalResults ? response.totalResults : 0)
                    setLoader(false);
                   
                }
                else {
                    setLoader(false);
                    // notification.error({
                    //     message: '',
                    //     description: response.status.message,
                    //     placement: 'top'
                    // })

                }
            })
                .catch(error => {
                    debugger
                    setLoader(false);
                    notification.error({
                        message: '',
                        description: "error",
                        placement: 'top'
                    })
                })
        }
    }

    const getPreviewVideoData = (item: any) => {
        setLoader(true);
        let mapUrl: string = EndpointService.getMeetingVideoPreviewUrl + `?recoringUrl=${item}`;
        HTTPService.get(mapUrl, {}).then(response => {
            if (response.data) {
                setLoader(false);
                let videoPreviewLink: string = environment.imageBaseUrl + `jibrirecordings/${response.data}`;
                window.open(videoPreviewLink, '');
            }
            else {
                setLoader(false);
            }
        })
    }

    const downloadMeetingVideo = (item: any) => {
        setLoader(true);
        let mapUrl: string = EndpointService.getDownloadMeetingUrl + `?filepath=${item}`;
        HTTPService.get(mapUrl, {}).then(response => {
            if (response.data) {
                setLoader(false);
                let videoDownloadLink: string = response.data;
                window.open(videoDownloadLink,);
            }
            else {
                setLoader(false);
            }
        })
    }

    const changeText = (event: any) => {
        setSearchText(event.target.value);
        getPreviousMeetingList(start, limit, event.target.value);
    }

    const pageChange = (page: any,pageSizeOptions:any) => {
        debugger;
        let startValue: number =  page==1 ? (page * pageSizeOptions) - pageSizeOptions: (page * pageSizeOptions +1) - pageSizeOptions
        setStart(startValue);
        let limit = page*pageSizeOptions
        getPreviousMeetingList(startValue, limit, searchText);
    }

    const getPreviewButton = (item: any) => {
        if (item) {
            return (
                <Tooltip placement="top" title="View">
                    <Button type="primary" className="table-btn-3" onClick={() => getPreviewVideoData(item)}><EyeOutlined /></Button>
                </Tooltip>
            )
        }
        else {
            return (
                <Button type="primary" disabled={true} className="table-btn-3"><EyeOutlined /></Button>
            )
        }
    }

    const getDownloadButton = (item: any) => {
        if (item) {
            return (
                <Tooltip placement="top" title="Download">
                    <Button type="primary" className="table-btn-3" onClick={() => downloadMeetingVideo(item)}><DownloadOutlined /></Button>
                </Tooltip>
            )
        }
        else {
            return (
                <Button type="primary" disabled={true} className="table-btn-3"><DownloadOutlined /></Button>
            )
        }
    }
    const handleListCompanyName = (el: any) => {
        return <span className='companyName'>{el}</span>
    }
    const columns: any = [
        {
            title: 'Name',
            width: 250,
            dataIndex: 'name',
            key: 'name',
            render: (item: any, data: any) => {
                return (<>
            <div className="td-name flex">
                <div> {item}</div>

                <div className="highlighted"> 
                <Tooltip placement="top" title= {data.nameList && data.nameList.toString()}>
                and  2  Others
                </Tooltip>

                 {/* {data.nameList && data.nameList.map ((el:any)=> (<>{el}</>))} */}
                 
                 </div>
            </div>  
            </>) },
            defaultSortOrder: 'descend',
        },
        {
            title: 'Organization',
            width: 300,
            dataIndex: 'organization',
            key: 'organization',
            render: (item: any, data: any) => { return (<>{item && item.map((el: any) => (handleListCompanyName(el)))}</>) }
        },
        {
            title: 'Date & Time',
            width: 180,
            dataIndex: 'dateTime',
            key: 'dateTime',
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            width: 300,
        },

        {
            title: 'Link',
            dataIndex: 'meetingLink',
            key: 'meetingLink',
            width: 100,
            return: (item: any) => { checkTableNull(item) },
            render: (item: any, data:any) => {
                return (
                    <a href={data.meetingLink} className="icon-btn-group float-left" target="_blank">
                    <Tooltip placement="top" title={`${ item }`}>
                    <Button type="primary"className="table-btn-3"><VideoCameraAddOutlined /></Button>
                    </Tooltip>
                </a>
            )
            },
            defaultSortOrder: 'descend',
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (item: any) => (
                <div className="icon-btn-group flex">
                    {getPreviewButton(item)}
                    {getDownloadButton(item)}
                </div>
            ),
        },
    ];

    return (
        <div className="view-table">
            {loader ? <Spin /> : null}
            <Table columns={columns} dataSource={previousMeetingList} scroll={{ y: 'calc(100vh - 281px)', x: 0 }} pagination={{ total: totalData, onChange: pageChange ,   defaultPageSize:10,
      defaultCurrent:1,pageSizeOptions:[10, 20, 50, 100]}} />
        </div>
    );
}

export default PreviousMeetings;
