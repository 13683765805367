import { GlobalOutlined } from '@ant-design/icons'
import { Button, Checkbox } from 'antd'
import type { Dayjs } from 'dayjs';
import { TimePicker } from 'antd';
import moment from 'moment';
import { cypherService } from "../../utilities/cypher/cypherService";
export default function AvailabilityModal(props: any) {
    let isEdit = cypherService.getLocalStorage("isEditEdTech")
    const { setDays, days, handleCancel, dateErrors, handleOk } = props

    const onChange = (checkedValues: any, name: string) => {

        let dateList = days
        let result: any = dateList && dateList.map((el: any) => {
            if (el.name === checkedValues.target.value) {
                el["active"] = checkedValues.target.checked
            }
            return el
        })
        setDays([...result])

    };
    const checkedValue = (el: any) => {
        debugger
        return days.includes(el)
    }
    const onTimeChange = (range: any, name: string) => {

        const currentStartTime = range[0].format('HH:mm');
        const currentEndTime = range[1].format('HH:mm');
        const currentEndTimeMintues = range[1].diff(range[0], 'minutes');
        let dateList = days

        debugger
        if (currentEndTimeMintues > 30) {
            let result: any = dateList && dateList.map((el: any) => {
                debugger
                if (el.name === name) {
                    el["startTiming"] = currentStartTime
                    el["endTiming"] = currentEndTime
                    el["error"] = ""
                }
                return el
            })
            setDays(result)
        } else {
            let result: any = dateList && dateList.map((el: any) => {
                debugger
                if (el.name === name) {
                    el["error"] = "Selected  start time should be less than to end time"
                }
                return el
            })
            setDays(result)
        }

    };

    return (
        <div>

            <div className="card-div" >
                <div className="modal-header">
                    <p>Enter availability</p>
                    <div className="accessiblity-time py-4">
                        <GlobalOutlined /> <span>All time are in GST (GMT+ 4 Hours)</span>
                    </div>
                </div>
                <div className='mt-4 relative'>
                    {/* <pre className='text-white'>{JSON.stringify(days, null, 1)}</pre> */}
                    {
                        days.map((item: any, index: number) => (
                            <div className="available-days flex justify-between items-center relative" key={index}>
                                <div className='available-name'><Checkbox value={item.name} onChange={(e) => onChange(e, item.name)}
                                    checked={item.active}>{item.name}</Checkbox></div>
                                {item.active ? (
                                    <div className="available-time">
                                        {
                                            !item.isEdit ?
                                                (<TimePicker.RangePicker allowClear clearIcon format="HH:mm" placeholder={["Start time", "End time"]} onChange={(e) => onTimeChange(e, item.name)} />) :
                                                (<TimePicker.RangePicker disabled defaultValue={[moment(item.startTiming, 'HH:mm'), moment(item.endTiming, 'HH:mm')]} format="HH:mm" secondStep={30} allowClear clearIcon placeholder={["Start time", "End time"]}
                                                    onChange={(e) => onTimeChange(e, item.name)} />)
                                        }
                                    </div>) : (<p className='noDate'>Unavailable</p>)}
                                <div className='error pl-8'>
                                    {item.error}
                                </div>
                            </div>
                        ))
                    }
                    <div className='error'>
                        {dateErrors}
                    </div>
                </div>

                <div className="btns pt-12">
                    <Button className="btn-secondary rounded" onClick={handleCancel}>CANCEL</Button>
                    <Button className="btn-primary m-0" onClick={handleOk}>SUBMIT </Button>
                </div>
            </div>
        </div>
    )
}
