import { useEffect, useState } from "react";
import Header from "../header/Header";
import { Input, Steps, Radio, Button, message, Spin, Card, notification, Modal } from "antd";
import EndpointService from "../../utilities/constants/endpointService";
import HTTPService from "../../service/HTTPService";
import { sortBy } from "lodash";
import EmiratesList from "./EmiratesList";
import { useNavigate } from "react-router-dom";
import { cypherService } from "../../utilities/cypher/cypherService";
import countryList from "./CountryList";
import { DeleteOutlined, CheckCircleFilled, MinusCircleFilled, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import UaeEmiratesList from "./UaeEmiratesList";
import { ErrorMessage, FieldArray, Formik } from "formik";
import { Form, Select } from "formik-antd";
import { BsArrowRight } from "react-icons/bs";
import { AddServiceProviderInitialValues, AddServiceProviderValidationSchema } from "./AddServiceProviderSchema";
import { MdKeyboardBackspace } from "react-icons/md";
import QuestionHeader from "../schoolDetails/layout/QuestionHeader";
import { changeCountry, changeTextValue, changeUaeCountry, clearCountry, clearUaeCountry, deletePartnerRow, gccNoOptionQuestionChange, partnerLocationChange, partnerLocationClear, partnerNameChange, radioChange } from "../../hooks/country";
import TextArea from "antd/lib/input/TextArea";
import FormControlPdf from "./layout/forms/FormControlPdf";
import FormRadioButton from "./layout/forms/FormRadioButton";
import FromTextArea from "./layout/forms/FromTextArea";
import { handleCompanyDescription } from "./layout/forms/Formhelper";
import { getPlaceHolder } from "../schoolDetails/healper";
import PrioritiesGrid from "../priorities/PrioritiesGrid";
import AddedCredits from "../schoolDetails/AddedCredits";

const ServiceProviderDetails = () => {
  cypherService.setLocalStorage("isActivePriorities", false)
  const navigate = useNavigate();
  let serviceProviderId: any = cypherService.getLocalStorage("serviceProviderId");
  let isEditValue: any = cypherService.getLocalStorage("isServiceProviderEdit");
  let answerLists: any = [];

  const [loader, setLoader] = useState<boolean>(false);
  const [questionList, setQuestionList] = useState<any>([]);
  const [gccToggle, setGccToggle] = useState<boolean | null>(null);
  const [partnerField, setPartnerField] = useState<any>([{}]);
  const [method, setMethod] = useState<string>("");
  const [customerId, setCustomerId] = useState<any>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [questionData, setQuestionData] = useState<any>([]);
  const [certificateError, setCertificateError] = useState<string>("");
  const [editServiceProviderAnswerObject, setEditServiceProviderAnswerObject] = useState<any>(null);
  const [matchedProductCount, setMatchedProductCount] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [creditBalance, setCreditBalance] = useState<any>(0);
  const [creditBalanceTotal, setCreditBalanceTotal] = useState<any>(0)
  const [totalData, setTotalData] = useState<any>([])
  const [isCredit, setisCredit] = useState<any>(false)
  const [totalValue, settotalValue] = useState<number>(data.currentBalance);
  const [prioritiesCount, setPrioritiesCount] = useState({
    planningAndTeaching: 0,
    assessment: 0,
    curriculum: 0,
    leadershipManagement: 0,
    personalAndSocialDevelopment: 0
  })
  const [approvedStatus, setApprovedStatus] = useState<any>({
    status: "Pending Approval",
  });
  const [initAddServiceValue, setInitLoginFormValue] = useState<AddServiceProviderInitialValues>({
    personName: "",
    emailId: "",
    companyName: "",
    companyLocation: "",
    uaeEmirate: "",
    channelPartner: "",
    findingPartner: "",
    status: "",
    companyDescription: "",
    investors: "",
    otherInformation: "",
    partnerInfo: [
      {
        location: "",
        partnerName: "",
      },
    ],
  });
  useEffect(() => {
    if (isEditValue) {
      serviceProviderDetails();
      //  getSchoolAnswerDetails()
    } else {
      getAddServiceProviderQuestions();
    }
  }, []);



  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);

  };

  const serviceProviderDetails = () => {
    setLoader(true);
    const mapUrl = EndpointService.getSingleServiceProviderDetails + serviceProviderId;
    HTTPService.get(mapUrl).then((response) => {
      let data = response.data;
      setTotalData(data);
      if (data) {
        answerLists = data;
        setQuestionData(data);

        let balance = answerLists.currentBalance ? Number(answerLists.currentBalance) : 150
        setCreditBalance(balance)
        setCreditBalanceTotal(balance)


        let matchedProductCount = {}
          && answerLists.serviceProviderMatchedCustomerCount
          [0]
        let matched = {
          leadershipManagementProductCount: matchedProductCount.leaderShipManagementMatchedCustomerPriorityCount,
          assessmentProductCount: matchedProductCount.assessmentMatchedCustomerPriorityCount,
          curriculumProductCount: matchedProductCount.curriculumMatchedCustomerPriorityCount,
          personalAndSocialDevelopmentProductCount: matchedProductCount.personalAndSocialDevelopmentMatchedCustomerPriorityCount,
          planningAndTeachingProductCount: matchedProductCount.planningAndTeachingMatchedCustomerPriorityCount,
          matched: answerLists.serviceProviderWithRecommendedCustomer
        }
        setMatchedProductCount(matched)
        let obj = {
          planningAndTeaching: matchedProductCount.planningAndTeaching,
          assessment: matchedProductCount.assessment,
          curriculum: matchedProductCount.curriculum,
          leadershipManagement: matchedProductCount.leaderShipManagement,
          personalAndSocialDevelopment: matchedProductCount.personalAndSocialDevelopment

        }
        cypherService.setLocalStorage("matchedPriorities", answerLists.serviceProviderMatchedCustomerCount)

        setPrioritiesCount(obj)
        getAddServiceProviderQuestions();
        let getApprovedStatus = data.serviceProviderApprovedStatus && data.serviceProviderApprovedStatus[0];

        if (getApprovedStatus) {
          setApprovedStatus({
            ...getApprovedStatus,
            status: getApprovedStatus.status,
          });
        }
      }
    });
  };

  const getAddServiceProviderQuestions = () => {
    let mapUrl = EndpointService.getCustomerAssessmentUrl + "ServiceProviderCompanyAssessment/requiredToDisplayQuestionsFor?requiredToDisplayQuestionsFor=Admin";
    HTTPService.get(mapUrl).then((response) => {
      setLoader(false);

      let tempsortedResponse: any = sortBy(response.data, "displaySequence");

      getAllServiceProviderDetails(tempsortedResponse);
    });
  };

  const getAllServiceProviderDetails = (tempsortedResponse: any) => {
    let sortedResponse = tempsortedResponse;
    tempsortedResponse.forEach((element: any, index: number) => {
      if (element.componentType === "Text") {
        sortedResponse[index]["answer"] = "";
      }
      if (element.componentType === "Countrychecking") {
        sortedResponse[index]["isUae"] = false;
        sortedResponse[index]["isUaeAnswer"] = "";
        sortedResponse[index]["answer"] = null;
      }
      if (element.componentType === "GCC") {
        sortedResponse[index]["answer"] = "";
        sortedResponse[index]["serviceProviderQuestionaireId"] = "";
        tempsortedResponse.forEach((data: any) => {
          if (data.componentType === "YesNoRelatedQuestion") {
            sortedResponse[index]["selectNoOption"] = data;
            sortedResponse[index]["selectNoAnswer"] = "";
          }
        });
      }
    });

    let finalSortedResponse: any = [];
    sortedResponse.forEach((data: any) => {
      if (data.componentType !== "YesNoRelatedQuestion") {
        finalSortedResponse.push(data);
      }
    });

    let finalResponse: any = finalSortedResponse;

    if (isEditValue) {
      createAnswersObjects(finalSortedResponse, finalResponse);
    } else {
      setQuestionList(sortedResponse);
    }
  };

  const createAnswersObjects = (finalSortedResponse: any, finalResponse: any) => {
    setIsActive(answerLists.isActive);
    setCustomerId(answerLists.serviceProviderId);
    setAnswerObjectPayloadId(answerLists);
    let tempEditAnswerObjectResponse = setEditAnswerObject(finalSortedResponse, answerLists);
    finalResponse = tempEditAnswerObjectResponse;
    setQuestionList(finalResponse);
  };

  const setAnswerObjectPayloadId = (answerLists: any) => {
    let tempServiceProviderPayloadId: any = {};
    answerLists.serviceProviderQuestionaireList.forEach((item: any) => {
      if (item.questionaireOptionId !== null) {
        tempServiceProviderPayloadId[item.questionaireOptionId.toString()] = item.serviceProviderQuestionaireId;
      }
    });
    setEditServiceProviderAnswerObject(tempServiceProviderPayloadId);
  };

  const findStatus = (answerObject: any, item: any) => {
    let returnStatus = null;
    answerObject.serviceProviderApprovedStatus.map((data: any) => {
      if (item.questionaireId === data.questionaireId) {
        returnStatus = data.status;
      }
    });
    return returnStatus;
  };

  const setEditAnswerObject = (questionObject: any, answerObject: any) => {
    let tempQuestionObject: any = questionObject;
    let tempInitialValues: any = initAddServiceValue;

    questionObject.forEach((item: any, index: number) => {
      if (tempQuestionObject[index].code === "personName") {
        tempQuestionObject[index].answer = answerObject.name;
        tempInitialValues.personName = answerObject.name;
        item["status"] = findStatus(answerObject, item);
      } else if (tempQuestionObject[index].code === "emailId") {
        tempQuestionObject[index].answer = answerObject.email;
        tempInitialValues.emailId = answerObject.email;
        item["status"] = findStatus(answerObject, item);
      } else if (tempQuestionObject[index].code === "companyName") {
        tempQuestionObject[index].answer = answerObject.companyName;

        tempInitialValues.companyName = answerObject.companyName;
        item["status"] = findStatus(answerObject, item);
      } else if (tempQuestionObject[index].code === "companyDescription") {
        tempQuestionObject[index].answer = answerObject.companyDescription;
        tempInitialValues.companyDescription = answerObject.companyDescription;
        item["status"] = findStatus(answerObject, item);
      } else if (tempQuestionObject[index].code === "otherInformation") {
        // tempQuestionObject[index].answer = answerObject.companyDescription;
        // tempInitialValues.companyDescription = answerObject.companyDescription;
        // item["status"] = findStatus(answerObject, item);
        answerObject.serviceProviderQuestionaireList.forEach((data: any) => {
          item.questionaireOption &&
            item.questionaireOption.forEach((el: any) => {
              if (el.questionaireOptionId === data.questionaireOptionId) {
                let currentValue = data.otherText;
                tempQuestionObject[index].answer = currentValue;
                let payloadAnswers = {
                  otherText: data.otherText,
                  questionaireOptionId: data.questionaireOptionId,
                  serviceProviderId: serviceProviderId,
                  serviceProviderQuestionaireId: data.serviceProviderQuestionaireId,
                };

                tempQuestionObject[index].payloadAnswers = payloadAnswers;
                tempQuestionObject[index].answer = currentValue;
                tempInitialValues.otherInformation = currentValue;
              }
            });
        });
      } else if (tempQuestionObject[index].code === "investors") {
        answerObject.serviceProviderQuestionaireList.forEach((data: any) => {
          item.questionaireOption &&
            item.questionaireOption.forEach((el: any) => {
              if (el.questionaireOptionId === data.questionaireOptionId) {
                tempQuestionObject[index].selectNoAnswer = el.option;
                let payloadAnswers = {
                  otherText: el.option,
                  questionaireOptionId: data.questionaireOptionId,
                  serviceProviderId: serviceProviderId,
                  serviceProviderQuestionaireId: data.serviceProviderQuestionaireId,
                };
                tempQuestionObject[index].payloadAnswers = payloadAnswers;
                tempInitialValues.investors = el.option;
              }
            });
        });
        tempQuestionObject[index].componentValied = true;
      } else if (tempQuestionObject[index].code === "companyCertificate") {
        let obj: any = null;

        answerObject.serviceProviderQuestionaireList.forEach((data: any) => {

          if (data.filePath && !data.questionaireOption) {
            obj = {
              filePath: data.filePath,
              serviceProviderQuestionaireId: data.serviceProviderQuestionaireId,
            };
            tempQuestionObject[index]["isActiveFilePath"] = true;
            tempQuestionObject[index].answer = obj;
            tempQuestionObject[index].answer = obj;
          }
          else {
            item.questionaireOption &&
              item.questionaireOption.forEach((opt: any) => {
                if (opt.questionaireOptionId === data.questionaireOptionId && data.otherText) {
                  let payloadAnswers = {
                    otherText: data.otherText,
                    questionaireOptionId: data.questionaireOptionId,
                    serviceProviderId: serviceProviderId,
                    serviceProviderQuestionaireId: data.serviceProviderQuestionaireId,
                  };
                  tempQuestionObject[index].payloadAnswers = payloadAnswers;
                  tempQuestionObject[index]["componentValied"] = true;
                  tempQuestionObject[index]["isActiveFilePath"] = false;
                  tempQuestionObject[index].answer = data.otherText;
                }
              });

          }
        });
        item["status"] = findStatus(answerObject, item);
      } else if (tempQuestionObject[index].code === "companyLocation") {
        answerObject.serviceProviderQuestionaireList.forEach((data: any) => {
          if (data.questionaireOption && data.questionaireOption.optionGroup == "Location") {
            if (data.questionaireOption.option === "OtherText") {
              tempInitialValues.companyLocation = data.otherText;
              tempQuestionObject[index].answer = data.otherText;
              tempQuestionObject[index].isUae = false;
              tempQuestionObject[index].isUaeAnswer = "";
            } else {
              tempInitialValues.companyLocation = "United Arab Emirates";
              tempInitialValues.uaeEmirate = data.otherText;
              tempQuestionObject[index].answer = "United Arab Emirates";
              tempQuestionObject[index].isUae = true;
              tempQuestionObject[index].isUaeAnswer = data.otherText;
            }
          }
        });

        item["status"] = findStatus(answerObject, item);
      } else if (tempQuestionObject[index].code === "channelPartner") {
        item["status"] = findStatus(answerObject, item);
        answerObject.serviceProviderQuestionaireList.forEach((data: any) => {
          if (data.questionaireOption && data.questionaireOption.optionGroup == "GCC") {
            tempInitialValues.channelPartner = data.questionaireOption.option;
            tempQuestionObject[index].answer = data.questionaireOption.option;
            tempQuestionObject[index].serviceProviderQuestionaireId = data.serviceProviderQuestionaireId;

            if (data.questionaireOption.option == "No") {
              tempQuestionObject[index].selectNoAnswer = data.otherText;
              tempInitialValues.findingPartner = data.otherText;
              setGccToggle(false);
            } else {
              setGccToggle(true);
              setPartnerField(JSON.parse(data.otherText));
              tempInitialValues.partnerInfo = JSON.parse(data.otherText);
            }
          }
        });
      }
    });

    setInitLoginFormValue(tempInitialValues);
    return tempQuestionObject;
  };

  const addNewPartnerField = (arrayHelpers: any) => {
    arrayHelpers.push({});
    let tempPartnerFields = partnerField;
    let newPartnerField: any = {};
    // newPartnerField["Location"] = "";
    // newPartnerField["Partner"] = "";
    tempPartnerFields.push(newPartnerField);
    setPartnerField([...tempPartnerFields]);
  };


  const onSubmit = (resetForm: any) => {
    let isActiveMultipart = true
    let serviceProviderAssessmentDto: any = {
      serviceProviderCompanyInfoDto: {},
      serviceProviderQuestionaireDto: [],
    };
    let formData = new FormData();
    let name: string = "";
    let email: string = "";
    let companyName: string = "";
    let companyDescription: string = "";
    let editServiceProviderObjectKeys: any = editServiceProviderAnswerObject ? Object.keys(editServiceProviderAnswerObject) : [];
    let errorStatus = true;
    questionList.forEach((element: any) => {
      if (element.question === "Contact person name") {
        name = element.answer;
      } else if (element.question === "Email ID") {
        email = element.answer;
      } else if (element.questionGroup === "INFO-Location") {
        let temp: any = null;
        if (element.isUae) {
          element.questionaireOption.forEach((data: any) => {
            if (data.option === "UAE") {
              temp = {
                questionaireOptionId: data.questionaireOptionId,
                otherText: element.isUaeAnswer,
              };
            }
          });
        } else {
          element.questionaireOption.forEach((data: any) => {
            if (data.option !== "UAE") {
              temp = {
                questionaireOptionId: data.questionaireOptionId,
                otherText: element.answer,
              };
            }
          });
        }

        if (isEditValue) {
          if (editServiceProviderObjectKeys.includes(temp.questionaireOptionId.toString())) {
            temp["serviceProviderQuestionaireId"] = editServiceProviderAnswerObject[temp.questionaireOptionId];
          }
        }

        temp && serviceProviderAssessmentDto.serviceProviderQuestionaireDto.push(temp);
      } else if (element.questionGroup === "INFO-Name") {
        companyName = element.answer;
      } else if (element.questionGroup === "INFO-Description") {
        companyDescription = element.answer;
      } else if (element.questionGroup === "INFO-Investors") {
        element.payloadAnswers && serviceProviderAssessmentDto.serviceProviderQuestionaireDto.push(element.payloadAnswers);
      } else if (element.questionGroup === "INFO-Other Information") {
        element.payloadAnswers && serviceProviderAssessmentDto.serviceProviderQuestionaireDto.push(element.payloadAnswers);
      } else if (element.questionGroup === "INFO-Partners") {
        let temp: any = {
          ...(isEditValue && {
            serviceProviderQuestionaireId: element.serviceProviderQuestionaireId,
          }),
        };

        if (!gccToggle) {
          element.questionaireOption.forEach((data: any) => {
            if (data.option === "No") {
              temp.questionaireOptionId = data.questionaireOptionId;
              temp.otherText = element.selectNoAnswer;
            }
          });
        } else {
          element.questionaireOption.forEach((data: any) => {
            if (data.option === "Yes") {
              temp.questionaireOptionId = data.questionaireOptionId;
              temp.otherText = JSON.stringify(partnerField);
            }
          });
        }

        if (Object.keys(temp).length !== 0) {
          serviceProviderAssessmentDto.serviceProviderQuestionaireDto.push(temp);
        }
      } else if (element.questionGroup === "CERTIFICATE-Certificate") {
        if (element.answer) {
          if (element.isActiveFilePath) {
            element.answer.files && formData.append("file", element.answer.files);
            isActiveMultipart = true
          } else {
            let temp: any = {
              ...(isEditValue && {
                ...element.payloadAnswers
              })
            };
            element.questionaireOption.forEach((data: any) => {
              if (data.option === "Enter trade license") {
                temp.questionaireOptionId = data.questionaireOptionId;
                temp.otherText = element.answer;
              }
            });
            isActiveMultipart = false
            serviceProviderAssessmentDto.serviceProviderQuestionaireDto.push(temp)
          }
          errorStatus = false;
        }

      }
    });
    if (errorStatus) {
      setCertificateError("Certificate is required")
    } else {
      setCertificateError("")
      setLoader(true);

      serviceProviderAssessmentDto.serviceProviderCompanyInfoDto = {
        companyName: companyName,
        companyDescription: companyDescription,
      };

      let userId = cypherService.getLocalStorage("userId");
      let singleServiceProviderDetails = cypherService.getLocalStorage("singleServiceProviderDetails");
      // let keyCloakUserId = singleServiceProviderDetails && singleServiceProviderDetails.serviceProviderObject && singleServiceProviderDetails.serviceProviderObject.keyCloakUserId;
      // let keyCloakUserId:answerLists.keyCloakUserId;


      let payload: any = {
        serviceProviderAssessmentDto: serviceProviderAssessmentDto,
        email: email,
        name: name,
        partyDiscriminator: "ServiceProvider",
        currentBalance: creditBalanceTotal,
        userDetailId: userId,
        keyclockUserId: totalData.keyCloakUserId,
        // isActive: totalData.isActive,
        isLoggedIn: totalData.isLoggedIn,
        ...(isEditValue && {
          serviceProviderApprovedStatusDto: [approvedStatus],
        }),
        ...(isEditValue && { serviceProviderId: customerId }),
        // ...(isEditValue && { keyclockUserId: keyCloakUserId }),
        ...(isEditValue && { signupStatus: questionData.signupStatus }),
      };

      let mapUrl = EndpointService.createCustomerUrl;
      let apiMethod: string = isEditValue ? "put" : "post";
      let editUrl = "party-questionarie?partyDiscriminator=ServiceProvider&" + "partySignupDtoString=" + encodeURIComponent(JSON.stringify(payload)) + `&isMultipartFileOrTradeLicense=${isActiveMultipart}` + `&isCredit=${isCredit}&isAdmin=${true}&isPrivacyPolicy=${true}`;
      let createUrl = "createPartyFromAdmin?isSubmit=true&partyDiscriminator=ServiceProvider&" + "partySignupDtoString=" + encodeURIComponent(JSON.stringify(payload)) + `&isMultipartFileOrTradeLicense=${isActiveMultipart}`;
      let url = isEditValue ? editUrl : createUrl;
      mapUrl = EndpointService.editCustomerUrl + url;

      HTTPService.customApiMethod(mapUrl, formData, apiMethod)
        .then((response) => {
          if (response.status.success === "Success") {
            message.success(response.status.message);
            cypherService.setSessionStorage("editServiceProviderObject", null);
            cypherService.setLocalStorage("isServiceProviderEdit", false);
            redirectToServiceProvider();
          } else if (response.status.success === "Fail") {
            message.error(response.status.message);
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.data.status.success === "Fail") {
            message.error(error.response.data.status.message);
          }
        });
    }
  };

  const redirectToServiceProvider = () => {
    cypherService.setSessionStorage("editServiceProviderObject", null);
    cypherService.setLocalStorage("isServiceProviderEdit", false);
    cypherService.setSessionStorage("detailsTab", "2");
    cypherService.setLocalStorage("singleServiceProviderDetails", "");
    navigate("/viewdata");
  };

  const getErrorEvaluator = (errorValues: any) => {
    let errorKeys: any = Object.keys(errorValues);
    let doc: any = document.getElementById(errorKeys[0]);
    doc.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const selectItems: any = [
    {
      value: "Approved",
      label: (
        <a>
          <CheckCircleFilled />
          <span>Approved</span>
        </a>
      ),
    },
    {
      value: "Pending Approval",
      label: (
        <a>
          <MinusCircleFilled />
          <span>Pending Approval</span>
        </a>
      ),
    },
    // {
    //     value: 'Raise a query',
    //     label: (
    //         <a>
    //             <QuestionCircleFilled />
    //             <span>Raise a query</span>
    //         </a>
    //     ),
    // },
  ];

  const deleteServiceProvider = (serviceProviderObject: any) => {
    setLoader(true);

    const mapUrl = EndpointService.deleteCustomerUrl + serviceProviderId + "?partyDiscriminator=ServiceProvider";
    HTTPService.delete(mapUrl)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          setLoader(false);
          notification.success({
            message: "",
            description: response.status.message,
            placement: "top",
          });
          redirectToServiceProvider();
        }
      })
      .catch((err) => {
        setLoader(false);
        notification.error({
          message: "",
          description: err.response.data && err.response.data.status.message,
          placement: "top",
        });
      });
  };
  const changeStatusValue = (name: string, val: any) => {
    setApprovedStatus({
      ...approvedStatus,
      status: val,
    });
  };

  const handleSelectedStatus = (name: string) => {
    let returnValue = "Pending Approval";
    if (approvedStatus.status !== "Pending Approval") returnValue = approvedStatus.status;
    return returnValue;
  };
  const inputDisabled = (code: any) => {
    return isEditValue && code === "emailId" ? true : false;
  };

  const handleAddNewProducts = () => {
    cypherService.setLocalStorage("isProductsEdit", false);
    navigate("/productDetails");
  };

  // console.log("questionData", questionData);

  return (
    <>
      {loader ? <Spin /> : null}
      <Header />
      <main>
        {loader ? <Spin /> : null}

        <div className="service-provider-details">
          <div className="page-header">
            <div className="page-heading">
              <span className="back" onClick={redirectToServiceProvider}>
                <MdKeyboardBackspace />
              </span>
              <h3>Service Provider Details</h3>
            </div>
            <div className="main view-table-button">

            </div>
            {isEditValue && (
              <div className="action-btn">
                <p>Balance Credits: <span>{creditBalanceTotal}</span></p>
                <Button className="btn-secondary not-rounded" htmlType="submit" onClick={handleOpenModal}>
                  Add Credits
                </Button>
                <Button
                  className="btn-secondary not-rounded"
                  type="primary"
                  onClick={() => {
                    handleAddNewProducts();
                  }}
                >
                  Add Product
                </Button>
                <Button className="btn-secondary not-rounded" disabled={isActive} htmlType="submit" onClick={() => setMethod("create")}>
                  Send Mail
                </Button>
                <Button className="btn-secondary not-rounded" htmlType="submit" onClick={deleteServiceProvider}>
                  Delete
                </Button>
              </div>
            )}
          </div>

          <Formik enableReinitialize initialValues={initAddServiceValue} validationSchema={AddServiceProviderValidationSchema} onSubmit={(values, { resetForm }) => onSubmit(resetForm)}>
            {({ errors, handleChange, isSubmitting, setFieldValue, values }) => {
              if (isSubmitting && Object.keys(errors).length > 0) {
                getErrorEvaluator(errors);
                console.log(values);
                console.log(errors);
              }
              return (
                <div className="schoolDetails-main">
                  <Form>
                    <div className="schoolDetails-card">
                      <div className="schoolDetails-header-filter flex justify-between items-center">
                        <h1>General details</h1>
                        {isEditValue && (
                          <Select
                            value={handleSelectedStatus("general_detail")}
                            name="approval"
                            className="approval-select"
                            onChange={(val) => {
                              changeStatusValue("general_detail", val);
                            }}
                            options={selectItems}
                          />
                        )}
                      </div>

                      <div className="card-div">
                        {questionList &&
                          questionList.map((item: any, index: number) => {
                            if (item.componentType === "Text") {
                              console.log("Text", item.answer);
                              return (
                                <Card className="mb-5" title={<>{QuestionHeader(item)}</>} bordered={false} style={{ width: "100%" }}>
                                  {/* Answer */}
                                  <div className="answer-field">
                                    <Input
                                      disabled={inputDisabled(item.code)}
                                      placeholder={getPlaceHolder(item)}
                                      id={item.code}
                                      name={item.code}
                                      onChange={(e) => {
                                        changeTextValue(e.target.value, questionList, setQuestionList, index);
                                        handleChange(e);
                                      }}
                                      value={item.answer}
                                    />
                                    <div className="error">
                                      <ErrorMessage name={item.code} />
                                    </div>
                                  </div>
                                </Card>
                              );
                            } else if (item.componentType === "Countrychecking") {
                              return (
                                <Card className="mb-5" title={<>{QuestionHeader(item)}</>} bordered={false} style={{ width: "100%" }}>
                                  {/* Answer */}
                                  <div className="answer-field">
                                    <div>
                                      <Select
                                        id={item.code}
                                        name={item.code}
                                        showSearch
                                        placeholder={getPlaceHolder(item)}
                                        allowClear
                                        // value={item.answer}
                                        filterOption
                                        className="textInputSelect"
                                        optionFilterProp="children"
                                        onClear={() => {
                                          clearCountry(questionList, setQuestionList, index);
                                        }}
                                        onSelect={(e: any) => {
                                          changeCountry(e, questionList, setQuestionList, index);
                                        }}
                                      >
                                        {countryList.countries.map((country: any) => {
                                          return <Select.Option value={country.name}>{country.name}</Select.Option>;
                                        })}
                                      </Select>
                                      <>
                                        <div className="error">
                                          <ErrorMessage name={item.code} />
                                        </div>
                                      </>
                                      {item.isUae ? (
                                        <div>
                                          <Select
                                            id="uaeEmirate"
                                            name="uaeEmirate"
                                            showSearch
                                            placeholder="Select emirate"
                                            filterOption
                                            allowClear
                                            defaultValue={item.isUaeAnswer === "" ? null : item.isUaeAnswer}
                                            className="textInputSelect"
                                            optionFilterProp="children"
                                            onClear={() => {
                                              clearUaeCountry(questionList, setQuestionList, index);
                                            }}
                                            onSelect={(e: any) => {
                                              changeUaeCountry(e, questionList, setQuestionList, index);
                                            }}
                                          >
                                            {UaeEmiratesList.emirates &&
                                              UaeEmiratesList.emirates.map((item: any) => {
                                                return <Select.Option value={item.name}>{item.name}</Select.Option>;
                                              })}
                                          </Select>
                                          <>
                                            <div className="error">
                                              <ErrorMessage name="uaeEmirate" />
                                            </div>
                                          </>
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="error">
                                      <ErrorMessage name="contactPersonName" />
                                    </div>
                                  </div>
                                </Card>
                              );
                            } else if (item.componentType === "TextArea") {
                              return (
                                <>
                                  <Card className="mb-5" title={<>{QuestionHeader(item)}</>} bordered={false} style={{ width: "100%" }}>
                                    <div className="answer-field">
                                      <TextArea
                                        placeholder={getPlaceHolder(item)}
                                        id={item.code}
                                        name={item.code}
                                        onChange={(e: any) => {
                                          handleCompanyDescription(e.target.value, questionList, setQuestionList, index);
                                          handleChange(e);
                                        }}
                                        value={item.answer}
                                        autoSize={{ minRows: 4 }}
                                      />
                                      <div className="error">
                                        <ErrorMessage name={item.code} />
                                      </div>
                                    </div>
                                  </Card>
                                </>
                              );
                            } else if (item.componentType === "GCC") {
                              return (
                                <>
                                  <Card className="mb-5" title={<>{QuestionHeader(item)}</>} bordered={false} style={{ width: "100%" }}>
                                    <div className="answer-field">
                                      <Radio.Group
                                        name={item.code}
                                        value={item.answer}
                                        id={item.code}
                                        onChange={(e) => {
                                          radioChange(e.target.value, questionList, setQuestionList, index, gccToggle, setGccToggle);
                                          handleChange(e);
                                        }}
                                      >
                                        <Radio value="Yes">Yes</Radio>
                                        <Radio value="No">No</Radio>
                                      </Radio.Group>

                                      <div className="error">
                                        <ErrorMessage name={item.code} />
                                      </div>

                                      {/* Partner name */}

                                      {gccToggle ? (
                                        <div className="add-Partner" id="partnerInfo">
                                          <FieldArray
                                            name="partnerInfo"
                                            render={(arrayHelpers) => {
                                              return (
                                                <>
                                                  {partnerField &&
                                                    partnerField.map((element: any, rowIndex: number) => (
                                                      <div className="add-Partner-main" key={rowIndex}>
                                                        <div className="add-Partner-main-input">
                                                          <Input
                                                            id={item.code}
                                                            placeholder="Partner's name"
                                                            name={`partnerInfo[${rowIndex}].partnerName`}
                                                            value={element["partnerName"]}
                                                            onChange={(e) => {
                                                              partnerNameChange(e.target.value, rowIndex, partnerField, setPartnerField, "partnerName");
                                                              handleChange(e);
                                                            }}
                                                          />
                                                          <div className="error">
                                                            <ErrorMessage name={`partnerInfo[${rowIndex}].Partner`} />
                                                          </div>
                                                        </div>
                                                        <div className="add-Partner-main-select">
                                                          <Select
                                                            name={`partnerInfo[${rowIndex}].location`}
                                                            showSearch
                                                            placeholder="Select emirate"
                                                            filterOption
                                                            allowClear
                                                            className="textInputSelect"
                                                            optionFilterProp="children"
                                                            value={element["location"] == "" ? null : element["location"]}
                                                            onClear={() => {
                                                              partnerLocationClear(rowIndex, partnerField, setPartnerField, "location");
                                                            }}
                                                            onSelect={(e: any) => {
                                                              partnerLocationChange(e, rowIndex, partnerField, setPartnerField, "location");
                                                              handleChange(e);
                                                            }}
                                                          >
                                                            {EmiratesList.emirates &&
                                                              EmiratesList.emirates.map((item: any, index: number) => {
                                                                return <Select.Option value={item.name}>{item.name}</Select.Option>;
                                                              })}
                                                          </Select>
                                                          <div className="error">
                                                            <ErrorMessage name={`partnerInfo[${rowIndex}].Location`} />
                                                          </div>
                                                        </div>
                                                        {rowIndex == 0 ? (
                                                          <></>
                                                        ) : (
                                                          <Button type="text" className="btn-text" onClick={() => deletePartnerRow(rowIndex, partnerField, setPartnerField, arrayHelpers)}>
                                                            <DeleteOutlined />
                                                          </Button>
                                                        )}
                                                      </div>
                                                    ))}
                                                  <Button type="text" className="btn-text" onClick={() => addNewPartnerField(arrayHelpers)}>
                                                    + Add another partner{" "}
                                                  </Button>
                                                </>
                                              );
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {gccToggle == false && (
                                            <div className="add-Partner-new">
                                              <p className="step-header">{item.selectNoOption.question}</p>
                                              <div>
                                                <Radio.Group
                                                  name={item.selectNoOption.code}
                                                  value={item.selectNoAnswer}
                                                  id={item.selectNoOption.code}
                                                  onChange={(e) => {
                                                    gccNoOptionQuestionChange(e.target.value, questionList, setQuestionList, index, gccToggle, setGccToggle);
                                                    handleChange(e);
                                                  }}
                                                >
                                                  <Radio value="Yes">Yes</Radio>
                                                  <Radio value="No">No</Radio>
                                                </Radio.Group>
                                              </div>
                                              <div className="error">
                                                <ErrorMessage name={item.selectNoOption.code} />
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </Card>
                                </>
                              );
                            } else if (item.componentType === "Radio") {
                              return (
                                <>
                                  <Card className="mb-5" title={<>{QuestionHeader(item)}</>} bordered={false} style={{ width: "100%" }}>
                                    <FormRadioButton item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} handleChange={handleChange} />
                                  </Card>
                                </>
                              );
                            } else if (item.componentType === "FreeText") {
                              return (
                                <>
                                  <FromTextArea item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} isEditValue={isEditValue} QuestionHeader={QuestionHeader} handleChange={handleChange} />
                                </>
                              );
                            } else if (item.componentType === "UploadPdf") {
                              return (
                                <>
                                  <Card className="mb-5" title={<>{QuestionHeader(item)}</>} bordered={false} style={{ width: "100%" }}>
                                    <FormControlPdf item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} isEditValue={isEditValue} serviceProviderDetails={serviceProviderDetails} certificateError={certificateError} setCertificateError={setCertificateError} />
                                  </Card>
                                </>
                              );
                            }
                          })}

                        {/* <PrioritiesGrid handleModalOpen={handleModalOpenOne} isActiveAdd={false} prioritiesCount={prioritiesCount} matchedProductCount={matchedProductCount} /> */}

                      </div>
                      <div className="btn-group text-right pt-9">
                        <Button className="btn-primary " htmlType="submit" onClick={() => setMethod("submit")}>
                          {isEditValue && "Update and"} Save
                        </Button>
                      </div>
                    </div>

                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </main>


      <Modal open={isOpen} footer={null} className="remove-subcategory-modal credit-modal" onCancel={handleCloseModal}>
        <AddedCredits name={questionData.companyName} creditBalance={creditBalance} setisCredit={setisCredit} setCreditBalanceTotal={setCreditBalanceTotal} creditBalanceTotal={creditBalanceTotal} handleCloseModal={handleCloseModal} />
      </Modal>

    </>
  );
};

export default ServiceProviderDetails;
