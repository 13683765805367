import { GET_NOTIFICATIONS } from "./notificationsTypes";
import { SELECTEDPRODUCTS } from "./notificationsTypes";
export const initialState: any = [];
export const notificationsReducer = (state = initialState, action: any) => {
    const { type, data } = action;
    switch (type) {
        case GET_NOTIFICATIONS:
            return {
                data
            }
            
        default:
            return state;
    }
}


export const productState: any = null;

export const adminNotificationListReducer = (state = initialState, action: any) => {

    const { type, data } = action;

    switch (type) {
        case SELECTEDPRODUCTS:
            return {
                data
            }
          
        default:
            return state;
    }
}