import React, { useEffect, useState } from "react";
import { Button, Breadcrumb, Modal, Input, message, Spin, Calendar, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import { cypherService } from "../../../utilities/cypher/cypherService";


export default function NotificationsWigets() {
  let reduxStore = useSelector((state: RootStateOrAny) => state);
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    let messageData = reduxStore.notifications && reduxStore.notifications.data && reduxStore.notifications.data;
    setData(messageData)
  }, [reduxStore])

  const handelNavigate = (elm:any) =>{
    console.log(elm);
    
    if(elm.redirectTo=="Meeting"){
      navigate('/customermeetings')
    }
    if(elm.redirectTo=="EmailCampaign"){
      navigate('/campaigns')
    }
    if(elm.redirectTo=="ServiceProviderProduct"){
      cypherService.setLocalStorage("serviceProviderProductId",elm.serviceProviderProductId)
     
      navigate('/productDetails')
    }
    if(elm.redirectTo=="ServiceProvider"){
      cypherService.setLocalStorage("serviceProviderId",elm.serviceProvider.serviceProviderId)
      cypherService.setLocalStorage("isServiceProviderEdit", true);
      cypherService.setLocalStorage("isProductsEdit", true);
      navigate('/serviceProviderDetails')
    }

    if(elm.redirectTo=="Customer"){
      let schoolDetailsObj = {
        customerId: elm.customer.customerId,
        signupStatus: ""
      };
      cypherService.setLocalStorage("schoolDetailsObj", schoolDetailsObj);
      cypherService.setLocalStorage("schoolAction","isEdit")
      navigate('/schoolDetails')
    }
  }
  const handelNavigateAll = ()=>{
    cypherService.setLocalStorage("isgetUnread", true);
   navigate('/notifications/all')
  }

  return <div>
    <div className='dashboard-card notification-card'>
      {loader ? <Spin /> : null}
      <div className='card-header notice-head'>
        <p>Notifications</p>
        <p className="cursor-pointer"  onClick={() => handelNavigateAll()}>View all </p>
      </div>
      {data && data.map((item: any) => (
        <div className="notification-content">
          <div className="status">
            <p> <span></span></p>
          </div>
          <div className="notification-msg">
            <p className="cursor-pointer"  onClick={() => handelNavigate(item)}>{item.description}</p>
            <span>Today at 9:42 AM</span>
          </div>
        </div>
      ))}

    </div>
  </div>;
}
