import { Button, Input } from 'antd'
import React, { useState } from 'react'
import { InputNumber } from 'antd';

export default function AddedCredits(props: any) {
  const { name, creditBalance, setCreditBalanceTotal, creditBalanceTotal, setisCredit, handleCloseModal } = props
  let currentBalance: any = creditBalance
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: any) => {
    const min = 0;
    const max = 5000;

    let value: any = Math.max(min, Math.min(max, Number(event.target.value)));
    let addCredit: any = parseInt(event.target.value)
    if (addCredit > 0) {
      let total = creditBalance + value
      setCreditBalanceTotal(total)
      setisCredit(true)
    } else {
      setCreditBalanceTotal(value)
    }

    setInputValue(value)
  }
  return (
    <div>
      <div className="prod-details">
        <p className="modal-title">Add credits</p>
        <div className="grid grid-cols-2 gap-4 cred-detail ">
          <div className="organisation">
            <span>EdTech Providers</span>
            <p>{name}</p>
          </div>
          <div className="add-credit">
            <p>Current Balance <span>{currentBalance}</span></p>
            <label htmlFor="credit">Add credits</label>
            <input min="1" max="3" type="number" id="credit" value={inputValue} onChange={handleInputChange} />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 avail-credits">
          <div className="col-span-2 avail-credit">
            <p> Available Credits</p>
            <span>{currentBalance}</span>
          </div>
          <div className="avail-credit">
            <p>Added Credits</p>
            {inputValue ? (
              <p className="credit"><span>+</span>{inputValue}</p>
            ) : (
              <span>-</span>
            )}

          </div>
          <div className="avail-credit">
            <p>Total Balance</p>
            <p className="credit">{creditBalanceTotal}</p>
          </div>
        </div>
      </div>
      <div className="remove-confirmation-button">
        <Button className="btn-primary" type="primary" onClick={handleCloseModal}>Confirm</Button>
      </div>


    </div>
  )
}
