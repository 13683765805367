import React, { useEffect, useState } from "react";
import star from "../../../assets/icons/star_filled.svg";
import staroutline from "../../../assets/icons/star_outline.svg";
import EndpointService from "../../../utilities/constants/endpointService";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import HTTPService from "../../../service/HTTPService";
import { Spin, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { cypherService } from "../../../utilities/cypher/cypherService";
import { adminNotice } from "../../../store/notifications/notificationsAction";
import { Button, Divider, Segmented, Tooltip } from 'antd';
import moment from 'moment';



export default function NotificationsLists() {
  const { paramName } = useParams()
  const [data, setData] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch ();
  let reduxStore = useSelector((state: RootStateOrAny) => state);

  useEffect(() => {
    getNotification()
    return () => {
      cypherService.setLocalStorage("isgetUnread",false)
    };
  }, [paramName]);


  const getNotification = () => {

    let isUnreadFetch =  cypherService.getLocalStorage("isgetUnread");
    if(isUnreadFetch){
    let partyDiscriminator = "Admin";
    let mapUrl = ""
    mapUrl = paramName === "all" ? EndpointService.getNotificationList + `?limit=20&requiredToDisplayFor=${partyDiscriminator}&start=0` : EndpointService.getNotificationList + `?limit=20&requiredToDisplayFor=${partyDiscriminator}&start=0&notificationRequiredToDisplayFor=${paramName}`;
    setLoader(true)
    HTTPService.get(mapUrl)
      .then((response) => {
        let data = response.data
        setData(data)
        setLoader(false)
        reduxStore.adminNotice=response.totalResults;
        dispatch(adminNotice( reduxStore.adminNotice))
      })
      .catch((err) => {
        setLoader(false)
        message.error(err.response.data.status.message);
      });
    }

    else{
      let partyDiscriminator = "Admin";
      let mapUrl = ""
      mapUrl = paramName === "all" ? EndpointService.getNotificationList + `?limit=20&requiredToDisplayFor=${partyDiscriminator}&start=0` : EndpointService.getNotificationList + `?limit=20&requiredToDisplayFor=${partyDiscriminator}&start=0&notificationRequiredToDisplayFor=${paramName}`;
      setLoader(true)
      HTTPService.get(mapUrl)
        .then((response) => {
          let data = response.data
          setData(data)
          setLoader(false)
     
        })
        .catch((err) => {
          setLoader(false)
          message.error(err.response.data.status.message);
        });
    }

  }
const handelNavigate = (elm:any)=>{
debugger;
    let serviceProviderId =elm.serviceProvider ? elm.serviceProvider.serviceProviderId : elm.customer.customerId
    let mapUrl = EndpointService.setNotificationasRead+`?notificationId=${elm.notificationId}` +`&userId=${serviceProviderId}`;
    HTTPService.put(mapUrl,{})
      .then((response) => {
        // console.log(response.data);
        setLoader(false);
       
      })
      .catch((err) => {
        setLoader(false);
        // message.error(err.response.data.status.message);
      });
      if(elm.redirectTo=="Meeting"){
        navigate('/customermeetings') 
      }
      if(elm.redirectTo=="EmailCampaign"){
        navigate('/campaigns')
      }
      if(elm.redirectTo=="ServiceProviderProduct"){

        cypherService.setLocalStorage("serviceProviderProductId",elm.serviceProviderProductId ? elm.serviceProviderProductId : null )
      elm.description.includes("Testimonial")?  cypherService.setLocalStorage("handelscroll",true):cypherService.setLocalStorage("handelscroll",false)
       
        navigate('/productDetails')
      }
      if(elm.redirectTo=="ServiceProvider"){
        cypherService.setLocalStorage("serviceProviderId",elm.serviceProvider.serviceProviderId)
        cypherService.setLocalStorage("isServiceProviderEdit", true);
        cypherService.setLocalStorage("isProductsEdit", true);
        navigate('/serviceProviderDetails')
      }
  
      if(elm.redirectTo=="Customer"){
        debugger;
        let schoolDetailsObj = {
          customerId: elm.customer.customerId,
          signupStatus: ""
        };
        // console.log(elm, "gggg",schoolDetailsObj);
        cypherService.setLocalStorage("schoolDetailsObj", schoolDetailsObj);
        cypherService.setLocalStorage("schoolAction","isEdit")
        navigate('/schoolDetails')
      }
      
}


const checkedBookmarked = (el: any) => {

   let mapUrl = EndpointService.getNotificationList + `/${el.notificationId}/starred?userId=${ el.customer? el.customer.customerId : el.serviceProvider.serviceProviderId}`;

  setLoader(true);

  HTTPService.put(mapUrl, {})
    .then((response) => {
      if (response.status.success.toLowerCase() == 'success') {
        // message.success(response.status.message);
        getNotification();
      } else {
        setLoader(false);
        // message.error(response.status.message);
      }
    })
    .catch((errorResponse) => {
      // console.log(errorResponse);
      // message.error('failed');
      setLoader(false);
    });
};

const checkDate = (elm:any)=>{
  const date = elm.createdOn.split("T")[0];
  return date;

}
const checkTime = (elm:any) =>{
  const time = elm.createdOn.split("T")[1].substring(0, 8);
  const hour = parseInt(time.substring(0, 2), 10);
  const amPM = hour >= 12 ? "PM" : "AM";
  const formattedTime = `${hour % 12}:${time.substring(3, 5)}:${time.substring(6, 8)} ${amPM}`;
  return formattedTime
  
}

  return <div>
    {loader ? <Spin /> : null}
    {data && data.map((item: any) => (
      <div className="notification-list ">
        <div className="meeting-description text-style">

          <div className="reader-icon" onClick={() => checkedBookmarked(item)}>
            <img src={item.starred ? star : staroutline} alt="" />
          </div>

          <div className="school-name">
          <Tooltip placement="top" title= {item.description} >
          <p onClick={() => handelNavigate(item)} className={ item.isViewed ? "read truncate ..." : "truncate ..." }>
              {item.description}
            </p>
              </Tooltip>
          
          </div>
        </div>
        <div className="meeting-time text-style">
        <p>{item.createdOn ? moment(item.createdOn).format('llll') : '-'}</p>

          {/* <p>{checkDate(item)} &nbsp; {checkTime(item)}</p> */}
        </div>
      </div>

    ))}
  </div>;
}
