import { GET_NOTIFICATIONS } from "./notificationsTypes";
import { SELECTEDPRODUCTS } from "./notificationsTypes"; 


export const getNotifications = (object: any) => {
    return {
        type: GET_NOTIFICATIONS,
        data: object
    }
}
export const adminNotice =(object:any)=>{
    return{
        type:SELECTEDPRODUCTS,
        data:object
    }
}