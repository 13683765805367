import Header from "../header/Header";
import React, { useState } from "react";
import NotificationHeader from "./layout/Header";
import SideBar from "./layout/SideBar";
import NotificationsLists from "./layout/NotificationLists";

export default function Notifications() {
  const [activeChild, setActiveChild] = useState('child1');

  const handleChild1Click = () => {
    setActiveChild('lists');
  };

  const handleChild2Click = () => {
    setActiveChild('message');
  };
  return (
    <div className="new-dashboar">
      <Header></Header>
      <div className=" notification-vendor">
        <div className="notifcation-sidebar">
          <SideBar />
        </div>

        <div className="notification-menu ">
          <NotificationsLists></NotificationsLists>
        </div>
      </div>
    </div>
  );
}
