import { Route, Routes, Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import Login from "../components/login/Login";
import Dashboard from "../components/dashboard/Dashboard";
import AddServiceProvider from "../components/addServiceProvider/AddServiceProvider";
import ForgotPwd from "../components/login/ForgotPwd";
import ProtectedRoute from "./ProtectedRoute";
import { cypherService } from "../utilities/cypher/cypherService";
import ForgotPwdOtp from "../components/login/ForgotPwdOtp";
import PasswordCreation from "../components/login/PasswordCreation";
import SetPasswordFromMail from "../components/login/SetPasswordFromMail";
import ViewData from "../components/viewData/ViewData";
import AddAdmin from "../components/addAdmin/AddAdmin";
import Meetings from "../components/meetings/Meetings";
import Campaigns from "../components/campaigns/Campaigns";
import SchoolDetails from "../components/schoolDetails/SchoolDetails";
import ServiceProviderDetails from "../components/serviceProviderDetails/ServiceProviderDetails";
import ProductDetails from "../components/productDetails/ProductDetails";
import EdtechExpertDetails from "../components/EDTechExpert/EdtechExpertDetails";
import Notifications from "../components/notifications/Notifications";
import MessageConversation from "../components/message/MessageConversation";
import MessageConversationLists from "../components/message/MessageConversationLists";
import SingleMessageConversation from "../components/message/SingleMessageConversation";

function Routing() {
  const navigate = useNavigate();

  let isLoggedIn = cypherService.getLocalStorage("isLoggedIn");
  let token = cypherService.getSessionStorage("appToken");
  if (isLoggedIn && !token) {
    setTimeout(() => {
      navigate("viewData");
    }, 1);
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/forgotpassword" element={<ForgotPwd />}></Route>
      <Route path="/forgotpasswordotp" element={<ForgotPwdOtp />}></Route>
      <Route path="/passwordcreation" element={<PasswordCreation />}></Route>
      <Route path="/createPassword" element={<SetPasswordFromMail />}></Route>

      {/* protected url map */}
      <Route element={<ProtectedRoute />}>
        <Route path="/message-conversation" element={<MessageConversation />}></Route>
        <Route path="/message-conversation/:messageMasterId/" element={<MessageConversationLists />} />
        <Route path="/message-conversation/:messageMasterId/:messageNotificationId" element={<SingleMessageConversation />} />
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/addServiceProvider" element={<AddServiceProvider />}></Route>
        <Route path="addadmin" element={<AddAdmin />}></Route>
        <Route path="viewdata" element={<ViewData />}></Route>
        <Route path="/meetings" element={<Meetings />}></Route>
        <Route path="/campaigns" element={<Campaigns />}></Route>
        <Route path="/schoolDetails" element={<SchoolDetails />}></Route>
        <Route path="/serviceProviderDetails" element={<ServiceProviderDetails />}></Route>
        <Route path="/productDetails" element={<ProductDetails />}></Route>
        <Route path="/edtechExpertDetails" element={<EdtechExpertDetails />}></Route>
        <Route path="/notifications/:paramName" element={<Notifications />}></Route>

      </Route>
    </Routes>
  );
}

export default Routing;
