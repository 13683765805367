import { useEffect, useRef, useState } from "react";
import Header from "../header/Header";
import { Input, Steps, Button, Spin, message, Dropdown, Space, notification, Card, Radio, Checkbox, Modal } from "antd";
import EndpointService from "../../utilities/constants/endpointService";
import HTTPService from "../../service/HTTPService";
import { sortBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { cypherService } from "../../utilities/cypher/cypherService";
import { ErrorMessage, Formik } from "formik";
import { Form, Select, Cascader } from "formik-antd";
import { AddSchoolValidationSchema, AddSchoolInitialValues, statusType } from "./AddSchoolSchema";
import QuestionHeader from "./layout/QuestionHeader";
import { MdKeyboardBackspace } from "react-icons/md";
import { createPayload, getPlaceHolder, schoolInitialValues, statusValue } from "./healper";
import ConfirmMessageModal from "../modals/confirm/ConfirmMessageModal";
import MyPriorities from "./layout/MyPriorities";
import { CheckCircleFilled, MinusCircleFilled, PlusOutlined } from "@ant-design/icons";
import { changeSingleSelectValue } from "../../hooks/formHooks.ts/forms";
import SchoolDetailsTextFields from "./schoolForms/SchoolDetailsTextFields";
import SchoolDetailsNumberFields from "./schoolForms/SchoolDetailsNumberFields";
import SchoolDetailsTextarea from "./schoolForms/SchoolDetailsTextarea";
import SchoolDetailsCascader from "./schoolForms/SchoolDetailsCascader";
import PrioritiesGrid from "../priorities/PrioritiesGrid";
import AddCreditModal from "../modals/confirm/AddCreditModal";
import AddedCredits from "./AddedCredits";

const { TextArea } = Input;
cypherService.setLocalStorage("prioritiesCount", {
  planningAndTeaching: 0,
  assessment: 0,
  curriculum: 0,
  leadershipManagement: 0,
  personalAndSocialDevelopment: 0
}
)
let selectedPriorities: any = {
  planningAndTeaching: [],
  assessment: [],
  curriculum: [],
  leadershipManagement: [],
  personalAndSocialDevelopment: []
}
cypherService.setLocalStorage("selectedPriorities", selectedPriorities)
const SchoolDetails = () => {


  const formikRef: any = useRef();
  const schoolDetailsObj: any = cypherService.getLocalStorage("schoolDetailsObj");


  let customerId: number = schoolDetailsObj && schoolDetailsObj.customerId;
  // let keyclockUserId: any = schoolDetailsObj && schoolDetailsObj.keyclockUserId;
  const isEdit = cypherService.getLocalStorage("schoolAction") == "isEdit" ? true : false;
  const navigate = useNavigate();
  let answersList: any = [];
  const [loader, setLoader] = useState<boolean>(false);
  const [questionList, setQuestionList] = useState<any>([]);
  const [gradeList, setGradeList] = useState<any>([]);
  const [selectedgradList, setSelectedGradList] = useState<any>([]);
  const [method, setMethod] = useState<string>("");
  const [editAgeGroupAnswerObject, setEditAgeGroupAnswerObject] = useState<any>(null);
  const [editCustomerAnswerObject, setEditCustomerAnswerObject] = useState<any>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [confirmModa, setConfirmModa] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false)
  const [matchedProductCount, setMatchedProductCount] = useState([])
  const [isActivePriorities, setIsActivePriorities] = useState<boolean>(false)
  const [userCountError, setUserCountError] = useState<string>("");
  const [creditBalance, setCreditBalance] = useState<any>(0);
  const [creditBalanceTotal, setCreditBalanceTotal] = useState<any>(0);
  const [isCredit, setisCredit] = useState<any>(false)
  const [schoolData, setSchoolData] = useState<any>()
  const [vendorMatchData, setVendorMatchData] = useState<any>()
  const [prioritiesCount, setPrioritiesCount] = useState({
    planningAndTeaching: 0,
    assessment: 0,
    curriculum: 0,
    leadershipManagement: 0,
    personalAndSocialDevelopment: 0
  })
  const [approvedStatus, setApprovedStatus] = useState<any>([
    {
      details: "general_detail",
      status: "Pending Approval",
    },
    {
      details: "priority_detail",
      status: "Pending Approval",
    },
  ]);
  const [allAnswerLists, setAllAnswerLists] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<AddSchoolInitialValues>({
    personName: "",
    roles: "",
    emailId: "",
    curricula: "",
    grades: "",
    emirate: "",
    schoolCategory: "",
    schoolName: "",
    studentCount: "",
    priority: [],
    priorityDescription: "",
    name: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isEdit) {
      getSchoolAnswerDetails();
    } else {
      getCurriculumGradeList();
    }
    return () => {
      cypherService.setLocalStorage("priorityObject", [])
      cypherService.setSessionStorage("priorityObject", JSON.stringify([]))
      cypherService.setLocalStorage("schoolAction","")

    }
  }, []);



  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };



  const handleModalOpen = () => {

    setModal(true)

  }

  const clearAll = () => {
    setInitialValues(schoolInitialValues);
    cypherService.setLocalStorage("schoolAction", "add");
  };
  const getSchoolAnswerDetails = () => {
    // const schoolDetailsObj: any = cypherService.getLocalStorage("schoolDetailsObj");
    // let customerId: number = schoolDetailsObj && schoolDetailsObj.customerId;
    setLoader(true);
    let mapUrl = EndpointService.getSchoolDetails + customerId;
    console.log(mapUrl);

    HTTPService.get(mapUrl)
      .then((response) => {
        setLoader(false);
        answersList = response.data;
        setAllAnswerLists(answersList);
        let balance = answersList.currentBalance ? Number(answersList.currentBalance) : 0
        setCreditBalance(balance)
        setCreditBalanceTotal(balance)
        let matchedProductCount = answersList.matchedProductCountDto && answersList.matchedProductCountDto[0]

        let obj = {
          planningAndTeaching: matchedProductCount.planningAndTeaching,
          assessment: matchedProductCount.assessment,
          curriculum: matchedProductCount.curriculum,
          leadershipManagement: matchedProductCount.leadershipManagement,
          personalAndSocialDevelopment: matchedProductCount.personalAndSocialDevelopment
        }
        setPrioritiesCount(obj)
        setMatchedProductCount(matchedProductCount)
        approvedStatus &&
          approvedStatus.map((item: any) => {
            answersList.customerApprovalStatus &&
              answersList.customerApprovalStatus.map((ans: any) => {
                if (item.details === ans.details) {
                  item["status"] = ans.status;
                  item["customerApprovedId"] = ans.customerApprovedId;
                }
              });
          });

        getCurriculumGradeList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurriculumGradeList = () => {
    setLoader(true);
    let mapUrl = EndpointService.getCurriculumListIUrl;
    console.log(mapUrl);

    HTTPService.get(mapUrl).then((response) => {
      let accordionWithMultiSelect = response.data;
      let tempAccordionWithMultiSelect: any = [];
      let curriculumList: any = [];
      accordionWithMultiSelect.forEach((item: any) => {
        let tempElement: any = item;
        let tempSubcategory: any = [];
        let tempData: any = {
          option: item.name,
        };
        curriculumList.push(tempData);
        item.ageCategories.forEach((element: any) => {
          let tempDataValue: any = {
            ...element,
            option: element.name,
          };
          tempSubcategory.push(tempDataValue);
        });
        tempElement.ageCategories = tempSubcategory;
        tempAccordionWithMultiSelect.push(tempElement);
      });
      setGradeList(tempAccordionWithMultiSelect);
      getAddSchoolQuestions(curriculumList, tempAccordionWithMultiSelect);
    });
  };

  const getAddSchoolQuestions = (curriculumList: any, tempAccordionWithMultiSelect: any) => {
    setLoader(true);
    let mapUrl = EndpointService.getCustomerAssessmentUrl + "CustomerAssessment/requiredToDisplayQuestionsFor?requiredToDisplayQuestionsFor=Admin";
    HTTPService.get(mapUrl).then((response) => {
      setLoader(false);
      let sortedResponse: any = sortBy(response.data, "displaySequence");
      let tempResponse: any = [];
      sortedResponse.forEach((item: any) => {
        if (item.questionGroup !== "PRIORITIES") {
          let tempItem: any = {
            ...item,
            answer: item.questionaireOption ? [] : "",
          };
          if (item.questionGroup == "SCHOOL-Curricula") {
            tempItem["previousQuestionaireOption"] = tempItem.questionaireOption;
            tempItem.questionaireOption = curriculumList;
          } else if (item.questionGroup == "SCHOOL-Grades") {
            tempItem["defaultAnswer"] = [];
          }
          tempResponse.push(tempItem);
        }
      });
      let finalResponse: any = [];
      if (isEdit) {
        setAnswerObjectPayloadId(answersList);
        setIsActive(answersList.isActive);
        let tempEditAnswerObjectResponse = setEditAnswersObject(tempResponse, answersList);
        let answerResponse = setEditGradeAnswerList(tempEditAnswerObjectResponse, tempAccordionWithMultiSelect);
        finalResponse = setEditGradeAnswerObject(answerResponse.finalQuestionList, answerResponse.tempSelectedGradList);

        setInitialValuesPayload(finalResponse);
      } else {
        finalResponse = tempResponse;
      }
      let sectorArray: any = []
      let vendorMatchArray: any = []
      console.log("finalResponse", finalResponse);
      finalResponse.forEach((element: any) => {
        if (element.questionaireId === 2) {
          element.questionaireOption.forEach((item: any) => {
            let obj = {
              option: item.option,
              questionaireOptionId: item.questionaireOptionId
            }
            sectorArray.push(obj)
            sectorArray.sort((a: any, b: any) => a.questionaireOptionId - b.questionaireOptionId)
            setSchoolData(sectorArray)
          })
        }

      });

      finalResponse.forEach((element: any) => {
        if (element.questionaireId === 219) {
          element.questionaireOption.forEach((item: any) => {
            let obj = {
              option: item.option,
              questionaireOptionId: item.questionaireOptionId
            }
            vendorMatchArray.push(obj)
            vendorMatchArray.sort((a: any, b: any) => a.questionaireOptionId - b.questionaireOptionId)
            console.log("vendorarray", vendorMatchArray);
            setVendorMatchData(vendorMatchArray)
          })


        }

      });
      setQuestionList([...finalResponse]);
    });
  };

  const setAnswerObjectPayloadId = (answersList: any) => {
    let tempCustomerPayloadId: any = {};
    let tempAgeGroupPayloadId: any = {};
    answersList &&
      answersList.customerCurriculumAgeCategoryMap.forEach((item: any) => {
        let curriculumAgeGroupId = item.curriculumAgeGroupAgeCategoryMapId;
        tempAgeGroupPayloadId[curriculumAgeGroupId && curriculumAgeGroupId.toString()] = item.customerCurriculumAgeCategoryMapId;
      });
    answersList.customerQuestionaire.forEach((item: any) => {
      let optionId = item.questionaireOption && item.questionaireOption.questionaireOptionId;
      tempCustomerPayloadId[optionId && optionId.toString()] = item.customerQuestionaireId;
    });
    setEditAgeGroupAnswerObject(tempAgeGroupPayloadId);
    setEditCustomerAnswerObject(tempCustomerPayloadId);
  };

  const setInitialValuesPayload = (finalResponse: any) => {
    let tempInitialValue: any = initialValues;
    finalResponse.forEach((item: any) => {
      if (item.code != "grades") {
        let ans = (tempInitialValue[item.code] = item.answer);
        tempInitialValue[item.code] = item.answer;
        if (item.code === "schoolName") {
          tempInitialValue[item.code] = ans && ans.length > 0 ? ans : "";
        }
      } else {
        tempInitialValue[item.code] = item.defaultAnswer;
      }
    });

    setInitialValues({ ...tempInitialValue });
  };

  const setEditGradeAnswerObject = (finalResponse: any, tempAccordionWithMultiSelect: any) => {
    let tempQuestionList = finalResponse;
    let tempAnswer: any = [];
    tempQuestionList.forEach((item: any, index: number) => {
      if (item.code == "grades") {
        tempAnswer = tempQuestionList[index].answerIdList;
      }
    });
    let tempAnswerOptionLength: any = {};
    let tempDefaultAnswer: any = {};
    tempAccordionWithMultiSelect.forEach((item: any) => {
      tempAnswerOptionLength[item.value] = item.children.length;
      tempDefaultAnswer[item.value] = [];
    });
    tempAnswer.forEach((item: any) => {
      tempAccordionWithMultiSelect.forEach((data: any) => {
        data.children.forEach((age: any) => {
          if (item.curriculumAgeGroupAgeCategoryMapId == age.id) {
            tempDefaultAnswer[data.value].push(age.value);
          }
        });
      });
    });
    let defaultAnswerKeys: any = Object.keys(tempDefaultAnswer);
    let defaultAnswer: any = [];
    let payloadAnswer: any = {};
    defaultAnswerKeys.forEach((item: any) => {
      if (tempAnswerOptionLength[item] == tempDefaultAnswer[item].length) {
        defaultAnswer.push([item]);
        payloadAnswer[item] = "All";
      } else {
        tempDefaultAnswer[item].forEach((data: any) => {
          defaultAnswer.push([item, data]);
          payloadAnswer[item] = tempDefaultAnswer[item];
        });
      }
    });
    tempQuestionList.forEach((item: any, index: any) => {
      if (item.code == "grades") {
        tempQuestionList[index].defaultAnswer = defaultAnswer;
        tempQuestionList[index].answer = payloadAnswer;
      }
    });
    return tempQuestionList;
  };

  const setEditGradeAnswerList = (tempEditAnswerObjectResponse: any, tempAccordionWithMultiSelect: any) => {
    let finalQuestionList = tempEditAnswerObjectResponse;
    let tempAnswer: any = [];
    let tempSelectedGradList: any = [];

    finalQuestionList.forEach((item: any, index: number) => {
      if (item.code == "curricula") {
        tempAnswer = finalQuestionList[index].answer;
      }
    });
    finalQuestionList.forEach((element: any, index: number) => {
      if (element.questionGroup === "SCHOOL-Grades") {
        tempAnswer.forEach((data: any) => {
          finalQuestionList[index].answer[data] = [];
        });
      }
    });
    tempAccordionWithMultiSelect.forEach((item: any) => {
      if (tempAnswer.includes(item.name)) {
        let tempElementData: any = {
          label: item.name,
          value: item.name,
          children: [],
        };
        let tempSubcategory: any = [];
        item.ageCategories.forEach((element: any) => {
          let tempSub: any = {
            label: element.name,
            value: element.name,
            id: element.curriculumAgeGroupAgeCategoryMapId,
          };
          tempSubcategory.push(tempSub);
        });
        tempElementData.children = tempSubcategory;
        tempSelectedGradList.push(tempElementData);
      }
    });
    setSelectedGradList([...tempSelectedGradList]);
    return {
      finalQuestionList: finalQuestionList,
      tempSelectedGradList: tempSelectedGradList,
    };
  };

  const setEditAnswersObject = (questionObject: any, answerObject: any) => {
    let tempQuestionObject: any = questionObject;
    questionObject.forEach((item: any, index: number) => {
      if (item.code == "personName") {
        tempQuestionObject[index].answer = answerObject.name;
      } else if (item.code == "emailId") {
        tempQuestionObject[index].answer = answerObject.email;
      } else if (item.code == "priority") {
        tempQuestionObject[index].answer = answerObject.customerPriorityConfiguration;
      } else if (item.code == "priorityDescription") {
        tempQuestionObject[index].answer = answerObject.description;
      } else if (item.code !== "grades") {
        answerObject &&
          answerObject.customerQuestionaire.forEach((data: any) => {
            let optionGroup = data.questionaireOption && data.questionaireOption.optionGroup;

            if (item.code == "roles" && optionGroup == "Role") {
              tempQuestionObject[index].answer.push(data.questionaireOption.option);
              tempQuestionObject[index]["customerQuestionaireId"] = data.customerQuestionaireId;
            } else if (item.code == "curricula" && optionGroup == "Curriculum") {
              tempQuestionObject[index].answer = data.otherText.split(",");
              if (data.customerQuestionaireId) {
                tempQuestionObject[index]["customerQuestionaireId"] = data.customerQuestionaireId;
              }
            } else if (item.code == "emirate" && optionGroup == "Emirate") {

              const answer = tempQuestionObject[index].answer
              const payloadAnswer = tempQuestionObject[index].payloadAnswer
              tempQuestionObject[index].answer = [...answer, data.otherText];
              let tempValue: any = {
                otherText: data.otherText,
                questionaireOptionId: data.questionaireOptionId,
                customerQuestionaireId: data.customerQuestionaireId

              };
              if (payloadAnswer) {
                tempQuestionObject[index]["payloadAnswer"] = [...payloadAnswer, tempValue];
              } else {
                tempQuestionObject[index]["payloadAnswer"] = [tempValue];
              }

            } else if (item.code == "studentCount" && optionGroup == "StudentCount") {

              item.questionaireOption && item.questionaireOption.forEach((el: any) => {
                if ("More than 10,000" === data.questionaireOption.option) {
                  tempQuestionObject[index]["moreThan10000"] = true;
                } else {
                  tempQuestionObject[index]["moreThan10000"] = false;
                }
              })
              tempQuestionObject[index]["validation"] = true;
              tempQuestionObject[index].answer = data.otherText;
            } else if (item.code == "schoolName" && optionGroup == "Institution") {

              tempQuestionObject[index].answer = data.otherText;
            } else if (item.code == "schoolCategory" && optionGroup == "Type") {
              tempQuestionObject[index].answer = data.questionaireOption.option;
              tempQuestionObject[index]["customerQuestionaireId"] = data.customerQuestionaireId;
            } else if (item.code == "name" && optionGroup == "schoolName") {
              tempQuestionObject[index].answer = data.questionaireOption.option;
              tempQuestionObject[index]["customerQuestionaireId"] = data.customerQuestionaireId;
            }
          });
      } else if (item.code == "grades") {
        tempQuestionObject[index].answerIdList = answerObject.customerCurriculumAgeCategoryMap;
      }
    });
    return tempQuestionObject;
  };

  const questionaireOptionId = (answer: string, ele: any) => {
    let questionId = null;

    ele &&
      ele.map((item: any) => {
        if (answer === item.option) {
          questionId = item.questionaireOptionId;
        }
      });
    return questionId;
  };

  const onSubmit = () => {
    let payloadAssets = {
      editAgeGroupAnswerObject: editAgeGroupAnswerObject,
      questionList: questionList,
      editCustomerAnswerObject: editCustomerAnswerObject,
      gradeList: gradeList,
      questionaireOptionId: questionaireOptionId,
      schoolDetailsObj: schoolDetailsObj,
      approvedStatus: approvedStatus,
      priorityAnswerLists: allAnswerLists && allAnswerLists.customerPriorityConfiguration,
      keyclockUserId: allAnswerLists && allAnswerLists.keyclockUserId ? allAnswerLists && allAnswerLists.keyclockUserId : null,
      isLoggedIn: allAnswerLists && allAnswerLists.isLoggedIn,
      isActive: allAnswerLists && allAnswerLists.isActive,
      // currentBalances: allAnswerLists && allAnswerLists.currentBalance,
      signupStatus: allAnswerLists && allAnswerLists.signupStatus,
      isEdit: isEdit,
      currentBalance: creditBalanceTotal,
    
    };
    let payload = createPayload(payloadAssets, allAnswerLists);
    debugger
    postApiCall(payload);
  };
  //?isSubmit=true&partyDiscriminator=Customer&partySignupDtoString
  const postApiCall = (payload: any) => {
    setLoader(true);
    let mapUrl = EndpointService.createCustomerUrl;
    let apiMethod: string = "post";
    // cypherService.getLocalStorage("schoolDetailsObj")

    if (isEdit) {
      payload["customerId"] = customerId;
      
      // payload["keyclockUserId"] = keyclockUserId;
      if (method == "submit") {
        mapUrl = EndpointService.editCustomerUrl + "party-questionarie?partyDiscriminator=Customer&" + "partySignupDtoString=" + encodeURIComponent(JSON.stringify(payload)) + `&isCredit=${isCredit}&isAdmin=${true}&isPrivacyPolicy=${true}`;
        apiMethod = "put";
      } else {
        mapUrl = mapUrl + "?isSubmit=false&partyDiscriminator=Customer&" + "partySignupDtoString=" + encodeURIComponent(JSON.stringify(payload));
      }
    } else {
      if (method === "submit") {
        mapUrl = mapUrl + `?isSubmit=true&partyDiscriminator=Customer&` + "partySignupDtoString=" + encodeURIComponent(JSON.stringify(payload));
      } else {
        mapUrl = mapUrl + "?isSubmit=false&partyDiscriminator=Customer&" + "partySignupDtoString=" + encodeURIComponent(JSON.stringify(payload));
      }
    }

    HTTPService.customApiMethod(mapUrl, [], apiMethod)
      .then((response) => {
        if (response.status.success === "Success") {
          message.success(response.status.message);
          navigateToViewSchool();
        } else if (response.status.success === "Fail") {
          message.error(response.status.message);
        }
        setLoader(true);
      })
      .catch((errorResponse) => {
        setLoader(false);
        if (errorResponse.response.data.status.success === "Fail") {
          message.error(errorResponse.response.data.status.message);
        }
      });
  };

  const navigateToViewSchool = () => {
    cypherService.setSessionStorage("detailsTab", "1");
    cypherService.setSessionStorage("editObject", null);
    clearAll();
    navigate("/viewdata");
  };

  const getErrorEvaluator = (errorValues: any) => {
    let errorKeys: any = Object.keys(errorValues);
    let doc: any = document.getElementById(errorKeys[0]);
    doc && doc.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const deleteCustomer = () => {
    handleModalClose();
    setLoader(true);
    const mapUrl = EndpointService.deleteCustomerUrl + customerId + "?partyDiscriminator=Customer";
    HTTPService.delete(mapUrl)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          setLoader(false);
          notification.success({
            message: "",
            description: response.status.message,
            placement: "top",
          });
          navigateToViewSchool();
        }
      })
      .catch((errorResponse) => {
        setLoader(false);
        notification.error({
          message: "",
          description: errorResponse.response.data.status.message,
          placement: "top",
        });
      });
  };
  const AddCredit = () => {
    handleModalClose()
    console.log("add");

  }

  const handleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const handleCreditModal = () => {
    setConfirmModa(!confirmModa);
    // console.log(data,"dddddddddddddddd"); 
  };


  const handleModalClose = () => {

    let prioritiesCount = cypherService.getLocalStorage("prioritiesCount")
    setPrioritiesCount(prioritiesCount)
    setIsActivePriorities(true)
    // setConfirmModal(false);
    setModal(false)
  };
  const selectItems: any = [
    {
      value: "Approved",
      label: (
        <a>
          <CheckCircleFilled />
          <span>Approved</span>
        </a>
      ),
    },
    {
      value: "Pending Approval",
      label: (
        <a>
          <MinusCircleFilled />
          <span>Pending Approval</span>
        </a>
      ),
    },
    // {
    //     value: 'Raise a query',
    //     label: (
    //         <a>
    //             <QuestionCircleFilled />
    //             <span>Raise a query</span>
    //         </a>
    //     ),
    // },
  ];

  const changeStatusValue = (name: string, val: any, lists: any) => {
    let result = lists.map((item: any) => {
      if (item.details === name) {
        item.status = val;
      }
      return item;
    });
    setApprovedStatus(result);
  };

  const handleSelectedStatus = (name: string, lists: any) => {
    let returnValue = "Pending Approval";
    lists.forEach((item: any) => {
      if (item.details === name) returnValue = item.status;
    });

    return returnValue;
  };

  const inputDisabled = (code: any) => {
    return isEdit && code === "emailId" ? true : false;
  };

  const handleChangeOne = (el: any, setFieldValue: any) => {
    setFieldValue("priority", [el.target.value])
    setIsActivePriorities(false)
  }
  return (
    <>
      <Header />

      <main>
        {loader ? <Spin /> : null}

        <div className="school-details">
          <div className="">
            <div className="page-header">
              <div className="page-heading">
                <span className="back" onClick={() => navigateToViewSchool()}>
                  <MdKeyboardBackspace />
                </span>
                <h3>School Detail</h3>

              </div>
              {isEdit && (
                <div className="action-btn">
                  <p>Balance Credits: <span>{creditBalanceTotal}</span></p>
                  {/* <p>Balance Credits: <span>{allAnswerLists.currentBalance}</span></p> */}

                  <Button className="btn-secondary not-rounded" htmlType="submit" onClick={handleOpenModal}>
                    Add Credits
                  </Button>

                  <Button className="btn-secondary not-rounded" disabled={isActive} htmlType="submit" onClick={() => setMethod("create")}>
                    Send Mail
                  </Button>

                  <Button className="btn-secondary not-rounded" htmlType="submit" onClick={handleConfirmModal}>
                    Delete
                  </Button>
                </div>
              )}
            </div>

            <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={AddSchoolValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
              {({ handleChange, errors, values, isSubmitting, setFieldValue }) => {
                console.log(values);
                if (isSubmitting && Object.keys(errors).length > 0) {
                  getErrorEvaluator(errors);
                }
                return (
                  <div className="schoolDetails-main">
                    <Form>
                      <div className="schoolDetails-card">
                        <div className="schoolDetails-header-filter flex justify-between items-center">
                          <h1>General details</h1>
                          {isEdit && (
                            <Select
                              value={handleSelectedStatus("general_detail", approvedStatus)}
                              name="approval"
                              className="approval-select"
                              onChange={(val) => {
                                changeStatusValue("general_detail", val, approvedStatus);
                              }}
                              options={selectItems}
                            />
                          )}
                        </div>
                        <div className="card-div">
                          {questionList &&
                            questionList.map((item: any, index: number) => {
                              if (item.componentType === "Text") {
                                return (
                                  <>
                                    <SchoolDetailsTextFields item={item} inputDisabled={inputDisabled} getPlaceHolder={getPlaceHolder} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} setUserCountError={setUserCountError} />
                                  </>
                                );
                              } else if (item.componentType === "RangeWithText") {
                                return (
                                  <>
                                    <SchoolDetailsNumberFields item={item} inputDisabled={inputDisabled} getPlaceHolder={getPlaceHolder} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} setUserCountError={setUserCountError} />
                                    {/* <pre>{JSON.stringify(item, null, 1)}</pre> */}

                                  </>
                                );
                              } else if (item.questionGroup === "SCHOOL-Grades") {
                                return (
                                  <><SchoolDetailsCascader item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} selectedgradList={selectedgradList} /></>
                                );
                              } else if (item.componentType === "ImageWithRadio1") {
                                return (
                                  <>
                                    <Card

                                      title={
                                        <>
                                          <QuestionHeader answer={item.answer} question={item.question} />
                                        </>
                                      }
                                      bordered={false}
                                      style={{ width: "100%" }}
                                    >
                                      {/* Answer */}
                                      <div className="answer-field">
                                        <Select
                                          id={item.code}
                                          name={item.code}
                                          showSearch
                                          allowClear
                                          onClear={() => singleSelectClear(questionList, setQuestionList, index)}
                                          defaultValue={item.answer}
                                          filterOption
                                          placeholder={getPlaceHolder(item)}
                                          className="textInputSelect"
                                          optionFilterProp="children"
                                          value={questionList[index].answer}
                                          onSelect={(e: any) => {
                                            changeSingleSelectValue(e, questionList, setQuestionList, index);
                                          }}
                                        >
                                          {item &&
                                            item.questionaireOption.map((data: any) => {
                                              return <Select.Option value={data.option}>{data.option}</Select.Option>;
                                            })}
                                        </Select>
                                        <div className="error">
                                          <ErrorMessage name={item.code} />
                                        </div>
                                      </div>
                                    </Card>
                                  </>
                                );
                              } else if (item.componentType === "TextWithRadio" && item.questionaireId == 2) {
                                return (
                                  <>
                                    <Card title={
                                      <>
                                        <QuestionHeader answer={item.answer} question={item.question} />
                                      </>
                                    }
                                      bordered={false}
                                      style={{ width: "100%" }}
                                    >

                                      <div className="answer-field">
                                        <Radio.Group
                                          name={item.code}
                                          value={questionList[index].answer}
                                          id={item.code}
                                          onChange={(e) => {
                                            radioChange(e.target.value, questionList, setQuestionList, index);
                                            handleChange(e);

                                          }}
                                        >
                                          {schoolData &&
                                            schoolData.map((data: any) => {
                                              return <Radio value={data.option}>{data.option}</Radio>;
                                            })}
                                          {/* {JSON.stringify(schoolData)} */}
                                        </Radio.Group>

                                        <div className="error">
                                          <ErrorMessage name={item.code} />
                                        </div>
                                      </div>

                                    </Card>
                                  </>
                                );
                              } else if (item.componentType === "TextWithRadio" && item.questionaireId == 219) {
                                return (
                                  <>
                                    <Card title={
                                      <>
                                        <QuestionHeader answer={item.answer} question={item.question} />
                                      </>
                                    }
                                      bordered={false}
                                      style={{ width: "100%" }}
                                    >

                                      <div className="answer-field">
                                        <Radio.Group
                                          name={item.code}
                                          value={questionList[index].answer}
                                          id={item.code}
                                          onChange={(e) => {
                                            radioChange(e.target.value, questionList, setQuestionList, index);
                                            handleChange(e);
                                          }}
                                        >
                                          {vendorMatchData &&
                                            vendorMatchData.map((data: any) => {
                                              return <Radio value={data.option}>{data.option}</Radio>;
                                            })}
                                        </Radio.Group>

                                        <div className="error">
                                          <ErrorMessage name={item.code} />
                                        </div>
                                      </div>
                                    </Card>
                                  </>
                                );
                              } else if (item.componentType === "SearchWithMultiSelect" || item.componentType === "ImageWithRadio") {
                                return (
                                  <Card

                                    title={
                                      <>
                                        <QuestionHeader answer={item.answer} question={item.question} />
                                      </>
                                    }
                                    bordered={false}
                                    style={{ width: "100%" }}
                                  >
                                    {/* Answer */}


                                    <div className="answer-field">
                                      <Select
                                        id={item.code}
                                        name={item.code}
                                        showSearch
                                        mode="multiple"
                                        defaultValue={item.answer}
                                        onDeselect={(e: any) => selectValueClear(e, questionList, setQuestionList, index, gradeList, setSelectedGradList)}
                                        filterOption
                                        placeholder={item.code == "roles" ? "Select the role" : "Select other curricula"}
                                        className="textInputSelect"
                                        optionFilterProp="children"
                                        value={questionList[index].answer}
                                        onSelect={(e: any) => {
                                          changeSelectValue(e, questionList, setQuestionList, index, gradeList, setSelectedGradList);
                                        }}
                                      >
                                        {item &&
                                          item.questionaireOption.map((dataValue: any) => {
                                            return <Select.Option value={dataValue.option}>{dataValue.option}</Select.Option>;
                                          })}
                                      </Select>

                                      <div className="error">
                                        <ErrorMessage name={item.code} />
                                      </div>
                                    </div>
                                  </Card>
                                );
                              }
                            })}
                        </div>
                      </div>

                      <div className="schoolDetails-card-2">
                        <div className="schoolDetails-header-filter flex justify-between items-center">
                          <h2>Priority detail</h2>
                          {isEdit && (
                            <Select
                              value={handleSelectedStatus("priority_detail", approvedStatus)}
                              name="approval"
                              className="approval-select"
                              onChange={(val) => {
                                changeStatusValue("priority_detail", val, approvedStatus);
                              }}
                              options={selectItems}
                            />
                          )}
                        </div>
                        <div className="card-div">
                          {questionList &&
                            questionList.map((item: any, index: number) => {
                              if (item.componentType === "TabWithMultiSelect") {
                                return (
                                  <>
                                    <Card

                                      title={
                                        <>
                                          <QuestionHeader answer={item.answer} question={item.question} />
                                        </>
                                      }
                                      bordered={false}
                                      style={{ width: "100%" }}
                                    >
                                      {/* Answer */}
                                      <div className="answer-field">
                                        <div className="w-full">
                                          {/* <MyPriorities defaultList={item} handleChange={handleChange} isEdit={isEdit} /> */}

                                          <Modal
                                            className="myPriorities-modal"
                                            open={modal}
                                            onOk={handleModalClose}
                                            onCancel={handleModalClose}
                                          >
                                            <MyPriorities defaultList={item} handleChange={handleChangeOne} isEdit={isEdit} setFieldValue={setFieldValue} />

                                          </Modal>

                                          <PrioritiesGrid handleModalOpen={handleModalOpen} isActiveAdd={false} prioritiesCount={prioritiesCount} matchedProductCount={matchedProductCount} defaultList={item} isActivePriorities={isActivePriorities} />

                                        </div>
                                        <div className="error-relative">
                                          <ErrorMessage name={item.code} />
                                        </div>
                                      </div>
                                    </Card>
                                  </>
                                );
                              } else if (item.componentType === "TextArea") {
                                return (
                                  <>
                                    <SchoolDetailsTextarea item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} handleChange={handleChange} />
                                  </>
                                );
                              }
                            })}
                        </div>
                        <div className="btn-group text-right pt-5">
                          <Button className="btn-primary m-0" htmlType="submit" onClick={() => setMethod("submit")}>
                            {isEdit ? "Update and Save" : "Save"}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </div>


        </div>
      </main>

      <ConfirmMessageModal modalShow={confirmModal} handleSuccess={deleteCustomer} handleModalClose={handleModalClose} />
      {/* <AddCreditModal data={data} modalShow={confirmModa} handleConfirm={AddCredit} handleModalClose={handleModalClose} /> */}
      <Modal open={isOpen} footer={null} className="remove-subcategory-modal credit-modal" onCancel={handleCloseModal}>
        <AddedCredits name={allAnswerLists.name} setisCredit={setisCredit} creditBalance={creditBalance} setCreditBalanceTotal={setCreditBalanceTotal} creditBalanceTotal={creditBalanceTotal} handleCloseModal={handleCloseModal} />
      </Modal>
    </>
  );
};

const radioChange = (event: any, questionList: any, setQuestionList: any, index: number) => {
  let tempQuestionList = questionList;
  let tempAnswer = event;
  tempQuestionList[index].answer = tempAnswer;
  setQuestionList([...tempQuestionList]);
};

const singleSelectClear = (questionList: any, setQuestionList: any, index: any) => {
  let tempQuestionList = questionList;
  tempQuestionList[index].answer = [];
  setQuestionList([...tempQuestionList]);
};

const changeSelectValue = (event: any, questionList: any, setQuestionList: any, index: number, gradeList: any, setSelectedGradList: any) => {
  let tempQuestionList = questionList;
  let tempAnswer = tempQuestionList[index].answer;
  tempAnswer.push(event);

  if (questionList[index].questionGroup === "SCHOOL-Curricula") {
    setGradeAnswerObject(tempQuestionList, setQuestionList, tempAnswer);
    getGradeList(tempAnswer, gradeList, setSelectedGradList);

    tempQuestionList[index].answer = tempAnswer;
  }
  if (questionList[index].questionGroup === "SCHOOL-Location") {
    setGradeAnswerObject(tempQuestionList, setQuestionList, tempAnswer);
    getGradeList(tempAnswer, gradeList, setSelectedGradList);

    tempQuestionList[index].answer = tempAnswer;
  }
  if (questionList[index].code == "roles") {
    let val = event;
    tempQuestionList[index].answer = [val];
  }
  setQuestionList([...tempQuestionList]);
};

const selectValueClear = (event: any, questionList: any, setQuestionList: any, index: number, gradeList: any, setSelectedGradList: any) => {
  let tempQuestionList = questionList;
  let tempAnswer = tempQuestionList[index].answer;

  if (questionList[index].questionGroup === "SCHOOL-Curricula") {
    let itemIndex = tempAnswer.indexOf(event);
    tempAnswer.splice(itemIndex, 1);
    tempQuestionList[index].answer = tempAnswer;
    setQuestionList([...tempQuestionList]);
    setGradeAnswerObject(tempQuestionList, setQuestionList, tempAnswer);
    getGradeList(tempAnswer, gradeList, setSelectedGradList);
  }

  if (questionList[index].questionGroup === "SCHOOL-Location") {
    let itemIndex = tempAnswer.indexOf(event);
    tempAnswer.splice(itemIndex, 1);
    tempQuestionList[index].answer = tempAnswer;
    setQuestionList([...tempQuestionList]);
    // setGradeAnswerObject(tempQuestionList, setQuestionList, tempAnswer);
    // getGradeList(tempAnswer, gradeList, setSelectedGradList);
  }
  if (questionList[index].code == "roles") {
    tempQuestionList[index].answer = [];
  }
};

const setGradeAnswerObject = (tempQuestionList: any, setQuestionList: any, tempAnswer: any) => {
  let finalQuestionList = tempQuestionList;
  tempQuestionList.forEach((element: any, index: number) => {
    if (element.questionGroup === "SCHOOL-Grades") {
      tempAnswer.forEach((data: any) => {
        finalQuestionList[index].answer[data] = [];
      });
    }
  });
  setQuestionList([...tempQuestionList]);
};

const getGradeList = (tempAnswer: any, gradeList: any, setSelectedGradList: any) => {
  let tempSelectedGradList: any = [];
  gradeList.forEach((item: any) => {
    if (tempAnswer.includes(item.name)) {
      let tempElementData: any = {
        label: item.name,
        value: item.name,
        children: [],
      };
      let tempSubcategory: any = [];
      item.ageCategories.forEach((element: any) => {
        let tempSub: any = {
          label: element.name,
          value: element.name,
          id: element.curriculumAgeGroupAgeCategoryMapId,
        };
        tempSubcategory.push(tempSub);
      });

      tempElementData.children = tempSubcategory;
      tempSelectedGradList.push(tempElementData);
    }
  });
  setSelectedGradList([...tempSelectedGradList]);
};



export default SchoolDetails;
