import { useEffect, useState } from 'react';
import { cypherService } from '../../../utilities/cypher/cypherService';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
export default function MessageHeader(props: any) {
  const navigate = useNavigate();
  const [readMessageCount, setReadMessageCount] = useState<number>(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
  const { rangeList = [], redirect = false } = props;
  useEffect(() => {
    let readCount = 0;
    let unReadCount = 0;
    rangeList &&
      rangeList.forEach((item: any) => {
        if (item.isViewed) {
          readCount = readCount + 1;
        } else {
          unReadCount = unReadCount + 1;
        }
      });
    setReadMessageCount(readCount);
    setUnreadMessageCount(unReadCount);
  }, [rangeList]);
  const handleGoBack = () => {
    debugger;
    navigate(-1);
  };
  return (
    <div className="notification-header">
      <header>
        {redirect ? (
          <div className="page-heading">
            <p onClick={handleGoBack} className='cursor-pointer'>
              <span className="back">
                <MdKeyboardBackspace />
              </span>
              Back
            </p>

            {cypherService.getLocalStorage("edTechExpertName")}
          </div>
        ) : (
          <div className="page-message">
            <p>{cypherService.getSessionStorage('notificationHeaderName')} </p>
            <span className="totalmsg">{rangeList.length} Total message</span>
            <span className="dot-"></span>
            <span className="msgUnread">{unreadMessageCount} Unread</span>
          </div>
        )}
      </header>
    </div>
  );
}
