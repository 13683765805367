import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { RootStateOrAny, useSelector } from "react-redux";
import { cypherService } from "../../../utilities/cypher/cypherService";


export default function SideBar() {
  const userId = cypherService.getLocalStorage("userId");
  const notificationHeaderName = cypherService.getSessionStorage('notificationHeaderName');
  let reduxStore = useSelector((state: RootStateOrAny) => state);
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState<any>(null);
  const [vendorList, setVendorList] = useState<any>([])
  const [schoolList, setSchoolList] = useState<any>([])
  useEffect(() => {
    let messageData = reduxStore && reduxStore.message && reduxStore.message.data && reduxStore.message.data;
    let list: any = [];
    let schoolLists: any = []
    messageData &&
      messageData.forEach((item: any) => {
        let splitValue = item.requiredToDisplayFor && item.requiredToDisplayFor.split(',');
        if (splitValue.length == 1) {
          let objectTwo = {
            name: splitValue[0],
            masterId: item.messagingMasterId,
            count: item.countOfUnReadMessagesOfCustomerForAdmin,
          };
          schoolLists.push(objectTwo);
        }
        let objectOne = {
          name: splitValue[0],
          masterId: item.messagingMasterId,
          count: item.countOfUnReadMessagesOfServiceProviderForAdmin,
        };
        let objectTwo = {
          name: splitValue[1],
          masterId: item.messagingMasterId,
          count: item.countOfUnReadMessagesOfCustomerForAdmin,
        };
        splitValue[0] && list.push(objectOne);
        splitValue[1] && schoolLists.push(objectTwo);

        // splitValue.forEach((el: any) => {

        // })
      });


    let schoolFilterValue = schoolLists.filter((el: any) => {
      debugger
      if (el.name !== "Value Added Service") {
        return el
      }
    })
    setSchoolList(schoolFilterValue)
    setVendorList(list)
  }, [reduxStore]);


  const getListItemClassName = (item: any) => {
    return item === activeItem ? 'active' : '';
  };

  const handelSingleMessage = (el: any, index: number, name: string) => {
    setActiveItem(index)
    navigate(`/message-conversation/${name}-${el.masterId}`);
    cypherService.setSessionStorage('partyDiscriminator', name);
    if (el.name === "Ed-tech Expert" && name === "ServiceProvider") {
      cypherService.setSessionStorage('expertDetails', "EdTechExpert");
    }
    else if (el.name === "Value Added Service" && name === "ServiceProvider") {
      cypherService.setSessionStorage('expertDetails', "ValueAddedServices");
    } else {
      cypherService.setSessionStorage("expertDetails", "")
    }
    cypherService.setSessionStorage('notificationHeaderName', el.name);
    cypherService.setSessionStorage('SelectionPartyName', name);

  };

  return <div className="notication-sidebar">
    <div className="school-section">
      {/* school */}


      <ul className="school-menu">
        <li className="menu-active">Ed-tech provider</li>

        {vendorList &&
          vendorList.map((item: any, index: number) => (
            <>
              {item.name && item.name !== "Ed-tech provider" ? (
                <li className={getListItemClassName(index)} onClick={() => handelSingleMessage(item, index, "ServiceProvider")}>
                  {item.name}
                  {item.count == 0 ? "":  <span className="number">{item.count}</span> }
                </li>
              ) : null}
            </>
          ))}
        <li className="menu-active">School</li>

        {schoolList &&
          schoolList.map((item: any, index: number) => (
            <>
              {item.name && item.name !== " School" ? (
                <li className={getListItemClassName(index)} onClick={() => handelSingleMessage(item, index, "Customer")}>
                  {item.name}
                  {item.count == 0 ? "":  <span className="number">{item.count}</span> }
                 
                </li>
              ) : null}
            </>
          ))}
      </ul>
    </div>
  </div>

    ;
}
