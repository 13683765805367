import { useEffect, useState } from "react";
import Header from "../header/Header";
import { Button, message, Spin, Card, notification, Modal } from "antd";
import EndpointService from "../../utilities/constants/endpointService";
import HTTPService from "../../service/HTTPService";
import { sortBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { cypherService } from "../../utilities/cypher/cypherService";
import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { ErrorMessage, Formik } from "formik";
import { Form, Select } from "formik-antd";
import { MdKeyboardBackspace } from "react-icons/md";
import QuestionHeader from "../schoolDetails/layout/QuestionHeader";
import moment from "moment";
import { setEditAnswerObject } from "./helper";
import { infoValidation, othersValidation } from "./AddServiceProviderSchema";
import FormControlDatePicker from "./layout/forms/FormControlDatePicker";
import FormControlRangeWithText from "./layout/forms/FormControlRangeWithText";
import FormControlInput from "./layout/forms/FormControlInput";
import FormControlTextArea from "./layout/forms/FormControlTextArea";
import FormControlSelect from "./layout/forms/FormControlSelect";
import FormControlImage from "./layout/forms/FormControlImage";
import FormControlVideo from "./layout/forms/FormControlVideo";
import FormControlPdf from "./layout/forms/FormControlPdf";
import Testimonial from "./layout/forms/testimonial/Testimonial";
import ProductsMyPriorities from "../schoolDetails/layout/ProductsMyPriorities";
import FormControlSelectWithTag from "./layout/forms/FormControlSelectWithTag";
import PrioritiesGrid from "../priorities/PrioritiesGrid";

const ProductDetails = () => {
  let serviceProviderId = cypherService.getLocalStorage("serviceProviderId");
  const isEdit = cypherService.getLocalStorage("isProductsEdit");
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [questionList, setQuestionList] = useState<any>([]);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [prioritiesLists, setPrioritiesLists] = useState<any>();
  const [isEditInfo, setIsEditInfo] = useState<boolean>(false);
  const [otherError, setOtherError] = useState<boolean>(false);
  const [prioritiesError, setPrioritiesError] = useState<boolean>(false);
  const [logoError, setLogoError] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false)
  const [matchedProductCount, setMatchedProductCount] = useState<any>([])
  const [isActivePriorities, setIsActivePriorities] = useState<boolean>(false)
  const [infoInitialValues, setInfoInitialValues] = useState<any>({
    name: "",
    shortDescription: "",
    fullDescription: "",
    priorities: "",
  });
  const [approvedStatusInfo, setApprovedStatusInfo] = useState<any>([
    {
      details: "Info",
      status: "Pending Approval",
    },
  ]);
  const [approvedStatus, setApprovedStatus] = useState<any>([
    {
      details: "Details",
      status: "Pending Approval",
    },
    {
      details: "Asset",
      status: "Pending Approval",
    },
    {
      details: "Others",
      status: "Pending Approval",
    },
  ]);
  const [prioritiesCount, setPrioritiesCount] = useState({
    planningAndTeaching: 0,
    assessment: 0,
    curriculum: 0,
    leadershipManagement: 0,
    personalAndSocialDevelopment: 0
  })
  const [othersInitialValues, setOthersInitialValues] = useState<any>({
    curricula: "",
    yearLaunched: null,
    userBase: "",
    UserCount: "",
    ageGroups: "",
    logo: "",
    images: "",
    video: "",
    demoVideo: "",
    languages: "",
    supportedPlatform: "",
    caseStudy: "",
    productCertificate: "",
    testimonials: "",
    otherLanguage: "",
    subject: "",
    otherSubject: "",
  });

  const [_errors, setErrors] = useState<any>({
    logo: "",
    images: "",
    video: "",
    demoVideo: "",
    caseStudy: "",
    productCertificate: "",
    testimonials: "",
  });

  useEffect(() => {
    cypherService.setLocalStorage("saveArrayListPriorities", []);
    if (isEdit) {
      editInit();
    } else {
      getListedData();
    }
return()=>{
  cypherService.setLocalStorage("handelscroll",false)
}
 
  
  
  }, []);

const scrollBottom = ()=>{
  let scroll =cypherService.getLocalStorage("handelscroll")
if(scroll){
  const doc: any = document.getElementById("testimonial-bottom");
  doc && doc.scrollIntoView({ behavior: "smooth", block: "center" });

}
    
  
}

  const editInit = () => {
    getAllListedData();
    setVisible(true);
  };
  const getAllListedData = () => {
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");
    const mapUrl1 = EndpointService.getSingleProductDetails + serviceProviderProductId;
    const mapUrl2 = EndpointService.getPriorities + serviceProviderProductId + "/category";
    const mapUrl3 = EndpointService.getCurriculumListIUrl;
    const mapUrl4 = EndpointService.getAgeGroupListUrl;
    const mapUrl5 = EndpointService.getCustomerAssessmentUrl + "ProductAssessment/requiredToDisplayQuestionsFor?requiredToDisplayQuestionsFor=Admin";
    let endpoints = [mapUrl1, mapUrl2, mapUrl3, mapUrl4, mapUrl5];
    setLoader(true);
    Promise.all(endpoints.map((endpoint) => HTTPService.get(endpoint))).then(([{ data: answersList }, { data: priorityList }, { data: curriculumGradeList }, { data: ageGroupOptionsList }, { data: questionsList }]) => {
      let allResponse = {
        answersList: answersList,
        priorityList: priorityList,
        curriculumGradeList: curriculumGradeList,
        ageGroupOptionsList: ageGroupOptionsList,
        questionsList: questionsList,
      };
      getPrioritiesCounts(allResponse.answersList)
      setInfoInitialValues({
        ...infoInitialValues,
        name: answersList && answersList.name,
        shortDescription: answersList.shortDescription && answersList.shortDescription,
        fullDescription: answersList.description && answersList.description,
      });

      let approvedList = allResponse.answersList && allResponse.answersList.serviceProviderProductApprovalStatus;
      let arrayApprovedList: any = [];
      let arrayApprovedListInfo: any = [];

      approvedStatus &&
        approvedStatus.forEach((el: any) => {
          approvedList &&
            approvedList.forEach((item: any) => {
              if (item.details !== "Info") {
                if (el.details === item.details) {
                  let obj = {
                    ...el,
                    ...item,
                  };
                  arrayApprovedList.push(obj);
                }
              }
            });
        });

      approvedStatusInfo &&
        approvedStatusInfo.forEach((el: any) => {
          approvedList &&
            approvedList.forEach((item: any) => {
              if (item.details === "Info") {
                if ("Info" === el.details) {
                  arrayApprovedListInfo.push(item);
                }
              }
            });
        });

      arrayApprovedList.length > 0 && setApprovedStatus(arrayApprovedList);
      arrayApprovedListInfo.length > 0 && setApprovedStatusInfo(arrayApprovedListInfo);
      getCurriculumGradeList(allResponse);
    });
  };

  const getPrioritiesCounts = (el: any) => {
    let matchedProductCount = {} && el.serviceProviderProductMatchedCustomerCountList[0]
    let matched = {
      leadershipManagementProductCount: matchedProductCount.leaderShipManagementMatchedCustomerPriorityCount,
      assessmentProductCount: matchedProductCount.assessmentMatchedCustomerPriorityCount,
      curriculumProductCount: matchedProductCount.curriculumMatchedCustomerPriorityCount,
      personalAndSocialDevelopmentProductCount: matchedProductCount.personalAndSocialDevelopmentMatchedCustomerPriorityCount,
      planningAndTeachingProductCount: matchedProductCount.planningAndTeachingMatchedCustomerPriorityCount,
      matched: el.serviceProviderWithRecommendedCustomer
    }
    setMatchedProductCount(matched)
    let obj = {
      planningAndTeaching: matchedProductCount.planningAndTeaching,
      assessment: matchedProductCount.assessment,
      curriculum: matchedProductCount.curriculum,
      leadershipManagement: matchedProductCount.leaderShipManagement,
      personalAndSocialDevelopment: matchedProductCount.personalAndSocialDevelopment

    }

    setPrioritiesCount(obj)
  }

  const getListedData = () => {
    const mapUrl1 = EndpointService.getCurriculumListIUrl;
    const mapUrl2 = EndpointService.getAgeGroupListUrl;
    const mapUrl3 = EndpointService.getCustomerAssessmentUrl + "ProductAssessment/requiredToDisplayQuestionsFor?requiredToDisplayQuestionsFor=Admin";
    let endpoints = [mapUrl1, mapUrl2, mapUrl3];
    setLoader(true);
    Promise.all(endpoints.map((endpoint) => HTTPService.get(endpoint))).then(([{ data: curriculumGradeList }, { data: ageGroupOptionsList }, { data: questionsList }]) => {
      let allResponse = {
        curriculumGradeList: curriculumGradeList,
        ageGroupOptionsList: ageGroupOptionsList,
        questionsList: questionsList,
      };
      getCurriculumGradeList(allResponse);
    });
  };
  const handleOtherError = (el: any) => {
    setOtherError(el);
  };
  const getCurriculumGradeList = (allResponse: any) => {
    let { curriculumGradeList } = allResponse;

    let accordionWithMultiSelect: any = [];
    let curriculumList: any = [];
    curriculumGradeList &&
      curriculumGradeList.forEach((item: any) => {
        let tempElement: any = item;
        let tempSubcategory: any = [];
        let tempData: any = {
          option: item.name,
          value: item.curriculumId,
        };
        curriculumList.push(tempData);
        item.ageCategories.forEach((element: any) => {
          let tempDataValue: any = {
            ...element,
            option: element.name,
            value: element.ageCategoryId,
          };
          tempSubcategory.push(tempDataValue);
        });
        tempElement.ageCategories = tempSubcategory;
        accordionWithMultiSelect.push(tempElement);
      });
    getAddServiceProviderQuestions(allResponse, curriculumList, accordionWithMultiSelect);
  };

  const getAddServiceProviderQuestions = (allResponse: any, curriculumList: any, accordionWithMultiSelect: any) => {
    let { answersList, priorityList, ageGroupOptionsList, questionsList } = allResponse;
    let ageOptionList: any = [];
    ageGroupOptionsList &&
      ageGroupOptionsList.forEach((item: any) => {
        let opt = {
          ...item,
          option: item.name,
        };
        ageOptionList.push(opt);
      });
    let sortedResponse: any = sortBy(questionsList, "displaySequence");
    let tempResponse: any = [];
    sortedResponse &&
      sortedResponse.forEach((item: any) => {
        let tempItem: any = {
          ...item,
          answer: item.questionaireOption ? [] : "",
        };
        if (item.code === "yearLaunched") {
          tempItem.answer = {
            year: "",
            yearMoment: null,
          };
        }
        if (item.questionGroup == "INFO-Curricula") {
          tempItem["previousQuestionaireOption"] = tempItem.questionaireOption;

          let obj = {
            option: item.questionaireOption[0].option,
            value: item.questionaireOption[0].questionaireOptionId,
          };
          tempItem.questionaireOption = [obj, ...curriculumList];
        } else if (item.questionGroup === "INFO-Subject") {

          let currentCurriculaOptions = item.questionaireOption[0];
          let obj = {
            curriculumId: currentCurriculaOptions.questionaireOptionId,
            name: currentCurriculaOptions.option,
            option: currentCurriculaOptions.option,
          };

          accordionWithMultiSelect.splice(0, 0, obj);
          let option = item.questionaireOption;

          item["subjectList"] = [...option];
        } else if (item.questionGroup == "DETAILS-Age groups") {
          tempItem.questionaireOption = ageOptionList;
        }

        tempResponse.push(tempItem);
      });

    let finalSortedResponse: any = [];

    tempResponse.forEach((data: any) => {
      if (data.componentType !== "YesNoRelatedQuestion") {
        finalSortedResponse.push(data);
      }
    });

    if (isEdit) {
      setIsActive(answersList.isActive);
      setAnswerObjectPayloadId(answersList);
      setPrioritiesLists(allResponse.priorityList);
      //Assign All Question structure here only
      let editAnswerObjectResponse = setEditAnswerObject(finalSortedResponse, answersList, priorityList, othersInitialValues, moment, setOthersInitialValues, isEdit);
      setQuestionList(editAnswerObjectResponse);
    } else {
      setQuestionList(finalSortedResponse);
    }

    setLoader(false);
    scrollBottom();
  };

  const setAnswerObjectPayloadId = (answerList: any) => {
    let tempServiceProviderPayloadId: any = {};
    answerList.serviceProviderProductDetailList.forEach((item: any) => {
      let questionaireOptionId = item.questionaireOption && item.questionaireOption.questionaireOptionId;

      if (questionaireOptionId !== null) {
        tempServiceProviderPayloadId[questionaireOptionId.toString()] = item.serviceProviderProductId;
      }
    });
  };

  const onSubmitInfo = (values: any) => {
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");
    setLoader(true);
    let userId = cypherService.getLocalStorage("userId");
    let resultApprovedStatus: any = [];

    approvedStatusInfo &&
      approvedStatusInfo.map((item: any) => {
        if (item.details === "Info") {
          item["serviceProviderProductId"] = serviceProviderProductId;
          resultApprovedStatus.push(item);
        }
      });
    let payLoad: any = {
      name: values.name,
      shortDescription: values.shortDescription,
      description: values.fullDescription,
      serviceProviderId: serviceProviderId,
      ...(isEdit && { serviceProviderProductApprovalStatusDto: resultApprovedStatus }),
      ...(isEdit && { userDetailId: userId }),
    };
    let postUrl = EndpointService.getProductInfoUrl + "productinfo";
    let putUrl = EndpointService.getProductInfoUrl + serviceProviderProductId + "/productinfo";
    let mapUrl = postUrl;

    let apiMethod: string = "post";

    if (isEdit) {
      mapUrl = putUrl;
      apiMethod = "put";
    } else {
      mapUrl = isEditInfo ? putUrl : postUrl;
      apiMethod = isEditInfo ? "put" : "post";
    }

    HTTPService.customApiMethod(mapUrl, payLoad, apiMethod)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        if (getStatus == "success") {
          message.success(response.status.message).then(() => {
            let data = response.data;
            setInfoInitialValues({
              name: data.name,
              shortDescription: data.shortDescription,
              fullDescription: data.description,
            });
            cypherService.setLocalStorage("serviceProviderProductId", data.serviceProviderProductId);
            cypherService.setLocalStorage("serviceProviderId", data.serviceProviderId);
            setIsEditInfo(true);
            setVisible(true);
            getPrioritiesDetails();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        message.error(err.response.data.status.message);
      });
  };

  const getPrioritiesDetails = () => {
    setLoader(true);
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");
    let mapUrl = EndpointService.getPriorities + serviceProviderProductId + "/category";

    HTTPService.get(mapUrl)
      .then((response) => {
        setLoader(false);
        setPrioritiesLists(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postPrioritiesDetails = (priorities: any) => {

    setLoader(true);
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");

    let mapUrl = EndpointService.getPriorities + serviceProviderProductId + "/productinfo";
    let payload: any = { subProductCategoryId: [...priorities] };
    HTTPService.customApiMethod(mapUrl, { ...payload }, "put")
      .then((response) => { })
      .catch((error) => {
        notification.error({
          message: "",
          description: error,
          placement: "top",
        });
        console.log(error);
      });
  };

  const deleteRequest = (el: number | string) => {
    setLoader(true);
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");
    let mapUrl = EndpointService.getPriorities + serviceProviderProductId + `/subproduct-category/${el}`;
    HTTPService.delete(mapUrl)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          notification.success({
            message: "",
            description: response.status.message,
            placement: "top",
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: "",
          description: error,
          placement: "top",
        });
      });
  };

  const deletePrioritiesDetails = (priorities: any) => {
    setLoader(true);
    priorities &&
      priorities.forEach((item: any) => {
        deleteRequest(item);
      });
  };

  const curriculaId = (curriculumValue: any) => {
    let val = null;
    questionList &&
      questionList.forEach((el: any) => {
        if (el.code === "curricula") {
          el.questionaireOption &&
            el.questionaireOption.forEach((item: any) => {
              if (item.option === curriculumValue) {
                val = item.value;
              }
            });
        }
      });

    return val && val;
  };

  const getOtherLanguagesPayload = (item: any, requestPaylod: any) => {
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");
    let selectedIds: any = [];
    item.answerPayload.forEach((data: any) => {
      // selectedIds.push(data.questionaireOptionId)
      if (data.otherText) {
        requestPaylod.push({
          serviceProviderProductId: serviceProviderProductId,
          questionaireOptionId: data.questionaireOptionId,
          otherText: data.otherText,
        });
      } else {
        requestPaylod.push({
          serviceProviderProductId: serviceProviderProductId,
          questionaireOptionId: data.questionaireOptionId,
          ...(data.editQuerrorResponseestionId && { serviceProviderProductDetailId: data.editQuestionId }),
        });
      }
    });
  };
  const getOtherPlatformsPayload = (item: any, requestPaylod: any) => {
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");
    let selectedPlatformIds: any = [];
    item.answerPayload.forEach((element: any) => {
      selectedPlatformIds.push(element.questionaireOptionId);
      requestPaylod.push({
        serviceProviderProductId: serviceProviderProductId,
        questionaireOptionId: element.questionaireOptionId,
        ...(element.editQuestionId && { serviceProviderProductDetailId: element.editQuestionId }),
      });
    });
  };

  const onSubmitOthers = () => {
    let saveArrayListPriorities = cypherService.getLocalStorage("saveArrayListPriorities");
    let priorityObject = cypherService.getLocalStorage("priorityObject");
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");

    let priorities: any = [];
    let isValidPriorities = false;

    if (saveArrayListPriorities && saveArrayListPriorities.length >= 1) {
      isValidPriorities = true;
    }
    priorityObject &&
      priorityObject.forEach((item: any) => {
        if (item.answer.length >= 1) {
          isValidPriorities = true;
        }
        item.answer &&
          item.answer.forEach((innerItem: any) => {
            if (saveArrayListPriorities && saveArrayListPriorities.length > 0) {
              saveArrayListPriorities.forEach((el: number | string) => {
                if (el != innerItem.productCategoryId) {
                  let isValid = priorities.includes(innerItem.productCategoryId);
                  !isValid && priorities.push(innerItem.productCategoryId);
                }
              });
            } else {
              let isValid = priorities.includes(innerItem.productCategoryId);
              !isValid && priorities.push(innerItem.productCategoryId);
            }
          });
      });

    let removeArrayList = cypherService.getLocalStorage("removePriorityArray");
    let filterRemoveArrayList: any = [];
    saveArrayListPriorities &&
      saveArrayListPriorities.forEach((item: number | string) => {
        removeArrayList &&
          removeArrayList.forEach((el: number | string) => {
            if (item == el) {
              filterRemoveArrayList.push(el);
            }
          });
      });

    filterRemoveArrayList && filterRemoveArrayList.length > 0 && deletePrioritiesDetails(filterRemoveArrayList);
    let postPriorities: any = [];
    priorities &&
      priorities.forEach((item: number | string) => {
        let isValid = saveArrayListPriorities.includes(item);
        !isValid && postPriorities.push(item);
      });


    let isValidLogo = true;

    questionList.map((object: any) => {
      if (object.componentType === "UploadImage" && object.answer) {
        let objectLength = 0;
        if (typeof object.answer === "object") {
          objectLength = Object.keys(object.answer).length;
        } else {
          objectLength = object.answer.length;
        }
        if (objectLength > 0) {
          isValidLogo = true;
        } else {
          setLoader(false);
          isValidLogo = false;
          handleLogoError(true);
          let doc: any = document.getElementById("logo");
          doc && doc.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    });

    if (!isValidPriorities) {
      setLoader(false);
      setPrioritiesError(true);
      let doc: any = document.getElementById("priorities");
      doc && doc.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setPrioritiesError(false);
    }

    if (isValidLogo && isValidPriorities) {
      postPrioritiesDetails(postPriorities);
      let testimonialFormData = new FormData();
      // setLoader(true)

      let testimonialPayload: any = {
        serviceProviderProductTestimonialDto: [],
      };
      let isActiveTestimonial = false;

      questionList &&
        questionList.forEach((item: any, index: number) => {
          if (item.code === "testimonials") {
            cypherService.setSessionStorage("method", "");

            item.answer &&
              item.answer.forEach((ans: any) => {
                if (ans.changes) {
                  isActiveTestimonial = true;
                  if (!isEdit) {
                    let result = {
                      serviceProviderProductId: serviceProviderProductId,
                      name: ans.name,
                      designation: ans.designation,
                      institutionName: ans.institutionName,
                      email: ans.email,
                      ...(ans.link && { videoLink: ans.link }),
                      ...(ans.uploadContent && { otherText: ans.uploadContent }),
                    };
                    testimonialPayload.serviceProviderProductTestimonialDto.push(result);
                  } else {
                    let result = {
                      serviceProviderProductId: serviceProviderProductId,
                      name: ans.name,
                      email: ans.email,
                      designation: ans.designation,
                      institutionName: ans.institutionName,
                      ...(ans.uploadContent && { otherText: ans.uploadContent }),
                      ...(ans.link && { videoLink: ans.link }),
                      ...(ans.serviceProviderProductTestimonialId && { serviceProviderProductTestimonialId: ans.serviceProviderProductTestimonialId }),
                    };
                    testimonialPayload.serviceProviderProductTestimonialDto.push(result);
                  }
                  ans.files && testimonialFormData.append("productTestimonial", ans.files);
                }
              });
          }
        });

      let otherValidation = cypherService.getLocalStorage("otherValidation");
      let otherValue = cypherService.getLocalStorage("otherValue");

      if (otherValidation) {
        if (otherValue) {
          handleOtherError(false);
          othersFormsSubmit(isActiveTestimonial, testimonialPayload, testimonialFormData);
        } else {
          let doc: any = document.getElementById("otherLanguage");
          doc && doc.scrollIntoView({ behavior: "smooth", block: "center" });
          handleOtherError(true);
          setLoader(false)
        }
      } else {
        othersFormsSubmit(isActiveTestimonial, testimonialPayload, testimonialFormData);
      }
    }
  };

  const testimonialApiCall = (testimonialPayload: any, testimonialFormData: any) => {
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");
    let apiMethod: any = "";
    let mapUrl: any = null;

    let testimonialMethod = cypherService.getSessionStorage("testimonialMethod");

    if (isEdit) {
      if (testimonialMethod === "true") {
        apiMethod = "post";
        mapUrl = EndpointService.updateProductUrl + `/product_testimonial?serviceProviderProductTestimonialJsonDto=` + encodeURIComponent(JSON.stringify(testimonialPayload));
      } else {
        apiMethod = "put";
        mapUrl = EndpointService.updateProductUrl + `/${serviceProviderProductId}/product_testimonial?serviceProviderProductTestimonialJsonDto=` + encodeURIComponent(JSON.stringify(testimonialPayload));
      }
    } else {
      apiMethod = "post";
      mapUrl = EndpointService.updateProductUrl + `/product_testimonial?serviceProviderProductTestimonialJsonDto=` + encodeURIComponent(JSON.stringify(testimonialPayload));
    }

    if (testimonialPayload) {
      setLoader(true);
      HTTPService.customApiMethod(mapUrl, testimonialFormData, apiMethod)
        .then((response) => {
          if (response.status.success.toLowerCase() == "success") {
            message.success(response.status.message);
            redirectToServiceProvider();
          } else {
            setLoader(false);
            redirectToServiceProvider();
            message.error(response.status.message);
          }
        })
        .catch((errorResponse) => {
          console.log(errorResponse);
          message.error("failed");
          setLoader(false);
        });
    }
  };

  const othersFormsSubmit = (isActiveTestimonial: any, testimonialPayload: any, testimonialFormData: any) => {
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");
    let requestPaylod: any = [];
    let requestAgeGroupPayload: any = [];
    let formData = new FormData();
    let tempCurriculum: any = [];
    let ageGroupValue: any = [];
    let tempCurriculumAnswer: any = [];
    let finalRequestAgeGroupPayload: any = [];
    if (questionList) {
      questionList.map((item: any) => {
        if (item.questionGroup === "DETAILS-Year launched") {
          let tempPayload: any = {
            serviceProviderProductId: serviceProviderProductId,
            otherText: item.answer ? item.answer.year : "",
            questionaireOptionId: item.questionaireOption[0].questionaireOptionId,
          };
          if (item.editQuestionId) {
            tempPayload["serviceProviderProductDetailId"] = item.editQuestionId;
          }
          requestPaylod.push(tempPayload);
        }
        if (item.questionGroup === "DETAILS-User base") {
          let tempPayload: any = {
            serviceProviderProductId: serviceProviderProductId,
            otherText: item.answer,
            questionaireOptionId: item.questionaireOption[0].questionaireOptionId,
          };
          if (item.editQuestionId) {
            tempPayload["serviceProviderProductDetailId"] = item.editQuestionId;
          }
          requestPaylod.push(tempPayload);
        }
        if (item.questionGroup === "INFO-Subject") {

          let othersData: any = [];

          let otherQuestionaireOptionId = null;
          let otherProductDetailId = null;

          item.answer.map((el: any) => {
            if (el.othersField) {
              othersData.push(el.label);
              otherQuestionaireOptionId = el.optionValue;
              if (el.editQuestionId) {
                otherProductDetailId = el.editQuestionId;
              }
            } else {
              let tempPayload: any = {
                serviceProviderProductId: serviceProviderProductId,
                otherText: el.label,
                questionaireOptionId: el.optionValue,
              };
              if (el.editQuestionId) {
                tempPayload["serviceProviderProductDetailId"] = el.editQuestionId;
              }
              requestPaylod.push(tempPayload);
            }
          });

          if (othersData && othersData.length > 0) {
            let text = othersData.toString();


            let tempPayload: any = {
              serviceProviderProductId: serviceProviderProductId,
              otherText: text,
              questionaireOptionId: otherQuestionaireOptionId,
            };
            if (otherProductDetailId) {
              tempPayload["serviceProviderProductDetailId"] = otherProductDetailId;
            }
            requestPaylod.push(tempPayload);
          }

        }
        if (item.questionGroup === "INFO-Curricula") {
          let ansValue = item.answer;
          ansValue &&
            ansValue.map((curriculumValue: any) => {
              let curricula = curriculaId(curriculumValue);
              tempCurriculum.push(curricula);
            });

          // tempCurriculumAnswer = item.answerPayload && item.answerPayload;
        }
        if (item.questionGroup === "DETAILS-Age groups") {
          let arr = item.answerPayload;
          const filteredArr = arr.reduce((acc: any, current: any) => {
            const x = acc.find((itemInner: any) => itemInner.questionaireOptionId === current.questionaireOptionId);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          console.log(filteredArr);

          filteredArr &&
            filteredArr.forEach((data: any) => {
              ageGroupValue.push({
                ageGroupId: data.questionaireOptionId,
              });
            });
        }
        if (item.questionGroup === "OTHERS-Languages") {
          getOtherLanguagesPayload(item, requestPaylod);
        }
        if (item.questionGroup === "OTHERS-Supported platforms") {
          getOtherPlatformsPayload(item, requestPaylod);
        }
      });
    }

    if (requestPaylod) {
      questionList.map((object: any) => {
        if (object.componentType === "UploadImages" && object.answer) {
          object.answer.forEach((data: any) => {
            data.create && data.create && formData.append("productImages", data.files);
          });
        }
        if (object.questionGroup === "OTHERS-Case Study" && object.answer) {
          let objFile = object.answer;
          objFile.create && objFile.create && formData.append("productCaseStudy", objFile.files);
        }
        if (object.questionGroup === "OTHERS-Product Certificate" && object.answer) {
          let objFile = object.answer;
          objFile.create && objFile.create && formData.append("productCertificate", objFile.files);
        }
        if (object.code === "video" && object.answer) {
          object.answer.forEach((data: any) => {
            data.create && data.create && formData.append("promotionalVideo", data.files);
          });
        }
        if (object.code === "demoVideo" && object.answer) {
          object.answer.forEach((data: any) => {
            data.create && data.create && formData.append("demoVideo", data.files);
          });
        }
        if (object.componentType === "UploadVideos" && object.answer) {
          object.answer.forEach((data: any) => {
            data.create && object.create && formData.append("productTestimonials", data.files);
          });
        }
        if (object.componentType === "UploadImage" && object.answer) {
          let objFile = object.answer;
          objFile.create && objFile.create && formData.append("productRecentLogo", objFile.files);
        }
      });
    }

    let userId = cypherService.getLocalStorage("userId");
    let resultApprovedStatus: any = [];
    approvedStatus &&
      approvedStatus.map((item: any) => {
        if (item.details !== "Info") {
          item["serviceProviderProductId"] = serviceProviderProductId;
          resultApprovedStatus.push(item);
        }
      });
    setLoader(true);
    let payload = {
      serviceProviderProductDetailDtos: requestPaylod,
      serviceProviderProductCurriculumAndAgeGroupDto: {
        curriculumId: [...tempCurriculum],
        ageGroup: [...ageGroupValue],
      },
      serviceProviderProductApprovalStatusDto: resultApprovedStatus,
      ...(isEdit && { userDetailId: userId }),
    };

    let apiMethod = "post";
    let mapUrl: any = null;

    if (isEdit) {
      apiMethod = "put";
      mapUrl = EndpointService.updateProductUrl + `/${serviceProviderProductId}` + `/productdetail?serviceProviderId=${serviceProviderId}` + "&serviceProviderProductDetailDto=" + encodeURIComponent(JSON.stringify(payload));
    } else {
      apiMethod = "post";
      mapUrl = EndpointService.saveProductUrl + "/?serviceProviderProductDetailDto=" + encodeURIComponent(JSON.stringify(payload)) + "&serviceProviderProductId=" + serviceProviderProductId + `&serviceProviderId=${serviceProviderId}`;
    }

    HTTPService.customApiMethod(mapUrl, formData, apiMethod)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        cypherService.setLocalStorage("otherValidation", false);
        cypherService.setLocalStorage("otherValue", "");
        if (getStatus == "success") {
          message.success(response.status.message).then(() => {
            if (isActiveTestimonial) {
              testimonialApiCall(testimonialPayload, testimonialFormData);
            } else {
              setLoader(false);
              redirectToServiceProvider();
              notification.success({
                message: "",
                description: response.status.message,
                placement: "top",
              });
            }
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        message.error(err.response.data.status.message);
      });
  };

  const redirectToServiceProvider = () => {
    cypherService.setLocalStorage("saveArrayListPriorities", []);
    cypherService.setSessionStorage("editServiceProviderObject", null);
    cypherService.setSessionStorage("isServiceProviderEdit", false);
    cypherService.setLocalStorage("productsDetails", "");
    cypherService.setLocalStorage("serviceProviderId", "");
    cypherService.setLocalStorage("serviceProviderProductId", "");
    cypherService.setLocalStorage("isProductsEdit", "add");
    cypherService.setSessionStorage("detailsTab", "2");
    navigate("/viewdata");
  };

  const getErrorEvaluator = (errorValues: any) => {
    let errorKeys: any = Object.keys(errorValues);
    let doc: any = document.getElementById(errorKeys[0]);
    doc && doc.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const selectItems: any = [
    {
      value: "Approved",
      label: (
        <a>
          <CheckCircleFilled />
          <span>Approved</span>
        </a>
      ),
    },
    {
      value: "Pending Approval",
      label: (
        <a>
          <MinusCircleFilled />
          <span>Pending Approval</span>
        </a>
      ),
    },
    // {
    //     value: 'Raise a query',
    //     label: (
    //         <a>
    //             <QuestionCircleFilled />
    //             <span>Raise a query</span>
    //         </a>
    //     ),
    // },
  ];

  const selectValueClear = (event: any, questionList: any, setQuestionList: any, index: number) => {
    let tempQuestionList = questionList;
    let answer = tempQuestionList[index].answer;
    let answerPayload = tempQuestionList[index].answerPayload && tempQuestionList[index].answerPayload;
    if (questionList instanceof Array) {
      let resultAnswer =
        answer &&
        answer.filter((item: any) => {
          if (item !== event) return item;
        });
      tempQuestionList[index].answer = resultAnswer;

      let resultAnswerPayload =
        answerPayload &&
        answerPayload.filter((item: any) => {
          if (item.answerValue !== event) return item;
        });

      tempQuestionList[index].answerPayload = resultAnswerPayload;

      setQuestionList([...tempQuestionList]);
    } else {
      tempQuestionList[index].answer = [];
      setQuestionList([...tempQuestionList]);
    }
  };

  const changeTextValue = (event: any, questionList: any, setQuestionList: any, index: number) => {
    questionList[index].answer = event;
    setQuestionList(questionList);
    setVisible(false);
  };

  const changeStatusValue = (name: string, val: any, lists: any) => {
    let result = lists.map((item: any) => {
      if (item.details === name) {
        item.status = val;
      }
      return item;
    });

    if (name === "Info") {
      setVisible(false);
      setApprovedStatusInfo(result);
    } else {
      setApprovedStatus(result);
    }
  };

  const handleSelectedStatus = (name: string, lists: any) => {
    let returnValue = "Pending Approval";
    lists.forEach((item: any) => {
      if (item.details === name) returnValue = item.status;
    });
    return returnValue;
  };
  const productDelete = () => {
    setLoader(true);
    let serviceProviderProductId = cypherService.getLocalStorage("serviceProviderProductId");
    let mapUrl = EndpointService.deleteProductUrl + serviceProviderProductId;
    HTTPService.delete(mapUrl)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          notification.success({
            message: "",
            description: response.status.message,
            placement: "top",
          });
          redirectToServiceProvider();
        }
      })
      .catch((error) => {
        notification.error({
          message: "",
          description: error,
          placement: "top",
        });
      });
  };

  const handlePriorities = (e: any) => {
    setPrioritiesError(e);
  };

  const handleLogoError = (e: any) => {
    setLogoError(e);
  };

  const handleModalOpen = () => {
    setModal(true)
  }
  const handleModalClose = () => {

    let prioritiesCount = cypherService.getLocalStorage("prioritiesCount")
    setIsActivePriorities(true)
    setPrioritiesCount(prioritiesCount)
    setModal(false)
  };
  return (
    <>
      <Header />￼
      <main>
        {loader ? <Spin /> : null}
        <div className="product-details">
          <div className="page-header">
            <div className="page-heading">
              <span className="back" onClick={redirectToServiceProvider}>
                <MdKeyboardBackspace />
              </span>
              <h3>Product Details</h3>
            </div>
            {isEdit && (
              <div className="action-btn">
                {/* <Button className="btn-secondary not-rounded" disabled={isActive} htmlType="submit" onClick={() => setMethod("create")}>
                                        Send Mail
                                    </Button> */}
                <Button className="btn-secondary not-rounded" disabled={isActive} htmlType="submit" onClick={productDelete}>
                  Delete
                </Button>
              </div>
            )}
          </div>

          <div className="productDetails-main">
            <Formik enableReinitialize={true} initialValues={infoInitialValues} validationSchema={infoValidation} onSubmit={(values) => onSubmitInfo(values)}>
              {({ handleSubmit, setFieldValue, values, errors, getFieldProps, handleChange, isSubmitting }) => {
                if (isSubmitting && Object.keys(errors).length > 0) {
                  getErrorEvaluator(errors);
                }
                return (
                  <Form>
                    <div className="schoolDetails-card-5 productDetails-card">
                      <div className="schoolDetails-header-filter flex justify-between items-center">
                        <h1>Info</h1>
                        {isEdit && (
                          <Select
                            value={handleSelectedStatus("Info", approvedStatusInfo)}
                            name="approval"
                            className="approval-select"
                            onChange={(val) => {
                              changeStatusValue("Info", val, approvedStatusInfo);
                            }}
                            options={selectItems}
                          />
                        )}
                      </div>

                      <div className="card-div">
                        {questionList &&
                          questionList.map((item: any, index: number) => {
                            if (item.code === "name") {
                              return <FormControlInput item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} handleChange={handleChange} changeTextValue={changeTextValue} values={values.name} />;
                            } else if (item.code === "shortDescription" || item.code === "fullDescription") {
                              return <FormControlTextArea item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} handleChange={handleChange} changeTextValue={changeTextValue} value={values[item.code]} visible={visible} />;
                            } else if (item.code === "priorities") {
                              return (
                                <>
                                  {prioritiesLists && (
                                    <Card
                                      className="priorities-card mb-5"
                                      title={
                                        <>
                                          <QuestionHeader answer={item.answer} question={item.question} />
                                        </>
                                      }
                                      bordered={false}

                                    >
                                      {/* Answer */}
                                      <div className="answer-field">
                                        <div className="w-full">
                                          <div>
                                            {
                                              // item.answer && item.answer.map((data: any) => (
                                              <div className="customer-priorities flex items-center" id={item.code}>
                                                <div className="w-full">

                                                  <Modal
                                                    className="myPriorities-modal"
                                                    open={modal}
                                                    onOk={handleModalClose}
                                                    onCancel={handleModalClose}
                                                  >
                                                    <ProductsMyPriorities defaultList={isEdit ? item.answer : prioritiesLists} handleChange={handleChange} isEdit={isEdit} questionList={questionList} setQuestionList={setQuestionList} globalIndex={index} handlePriorities={handlePriorities} />

                                                  </Modal>
                                                  <PrioritiesGrid handleModalOpen={handleModalOpen} isActiveAdd={true} prioritiesCount={prioritiesCount} matchedProductCount={matchedProductCount} defaultList={isEdit ? item : prioritiesLists} isActivePriorities={isActivePriorities} />
                                                </div>
                                              </div>
                                              // ))
                                            }

                                            {prioritiesError ? <div className="error"> Please select atleast one priorities</div> : null}
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                  )}
                                </>
                              );
                            }
                          })}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>

            {visible && (
              <>
                <Formik enableReinitialize initialValues={othersInitialValues} validationSchema={othersValidation} onSubmit={onSubmitOthers}>
                  {({ handleSubmit, setFieldValue, values, errors, getFieldProps, handleChange, isSubmitting }) => {
                    console.log(values);
                    console.log("errors", errors);
                    if (isSubmitting && Object.keys(errors).length > 0) {
                      getErrorEvaluator(errors);
                    }
                    return (
                      <Form>
                        <div className="schoolDetails-card">
                          <div className="schoolDetails-header-filter ">
                            <h1>Details</h1>
                            {isEdit && (
                              <Select
                                value={handleSelectedStatus("Details", approvedStatus)}
                                name="approval"
                                className="approval-select"
                                onChange={(val) => {
                                  changeStatusValue("Details", val, approvedStatus);
                                }}
                                options={selectItems}
                              />
                            )}
                          </div>

                          <div className="card-div-1">
                            {questionList &&
                              questionList.map((item: any, index: number) => {
                                if (item.code === "ageGroups" || item.code === "curricula") {
                                  return (
                                    <>
                                      <FormControlSelect item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} selectValueClear={selectValueClear} />
                                    </>
                                  );
                                } else if (item.code === "subject") {
                                  return (
                                    <>
                                      <FormControlSelectWithTag item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} handleChange={handleChange} />
                                    </>
                                  );
                                } else if (item.code === "yearLaunched") {
                                  return (
                                    <>
                                      <FormControlDatePicker item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} isEdit={isEdit} />
                                    </>
                                  );
                                } else if (item.componentType === "RangeWithText") {
                                  return (
                                    <>
                                      <FormControlRangeWithText item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} />
                                    </>
                                  );
                                }
                              })}
                          </div>
                        </div>

                        <div className="schoolDetails-card-3">
                          <div className="schoolDetails-header-filter flex justify-between items-center">
                            <h1>Assets</h1>
                            {isEdit && (
                              <Select
                                value={handleSelectedStatus("Asset", approvedStatus)}
                                name="approval"
                                className="approval-select"
                                onChange={(val) => {
                                  changeStatusValue("Asset", val, approvedStatus);
                                }}
                                options={selectItems}
                              />
                            )}
                          </div>

                          <div className="card-div">
                            {questionList &&
                              questionList.map((item: any, index: number) => {
                                if (item.componentType === "UploadImage" || item.componentType === "UploadImages") {
                                  return (
                                    <>
                                      <FormControlImage item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} setErrors={setErrors} errors={_errors} editInit={editInit} handleLogoError={handleLogoError} logoError={logoError} />
                                    </>
                                  );
                                } else if (item.componentType === "UploadVideo") {
                                  return (
                                    <>
                                      <FormControlVideo item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} setErrors={setErrors} errors={_errors} editInit={editInit} />
                                    </>
                                  );
                                }
                              })}
                          </div>
                        </div>

                        <div className="schoolDetails-card-4">
                          <div className="schoolDetails-header-filter flex justify-between items-center">
                            <h1>Others</h1>
                            {isEdit && (
                              <Select
                                value={handleSelectedStatus("Others", approvedStatus)}
                                name="approval"
                                className="approval-select"
                                onChange={(val) => {
                                  changeStatusValue("Others", val, approvedStatus);
                                }}
                                options={selectItems}
                              />
                            )}
                          </div>

                          <div className="card-div">
                            {questionList &&
                              questionList.map((item: any, index: number) => {
                                if (item.code === "languages" || item.code === "supportedPlatform") {
                                  return (
                                    <>
                                      <FormControlSelect item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} handleChange={handleChange} otherError={otherError} handleOtherError={handleOtherError} />
                                    </>
                                  );
                                } else if (item.componentType === "UploadPdf") {
                                  return <FormControlPdf item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} setErrors={setErrors} errors={_errors} isEdit={isEdit} editInit={editInit} />;
                                } else if (item.code === "testimonials") {
                                  return <Testimonial item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} setFieldValue={setFieldValue} setErrors={setErrors} errors={_errors} isEdit={isEdit} editInit={editInit} />;
                                }
                              })}

                            {/* Reviews */}
                            {/* {

                                                            isEdit && <ProductsReview />
                                                        } */}
                            {/* <ProductsReview /> */}
                            {/* <Select defaultValue="Pending Approval" onChange={handleChange} options={selectItems} name="approval" className="approval-select" /> */}
                          </div>
                          <div className="btn-group text-right pt-8">
                            <Button className="btn-primary m-0" htmlType="submit">
                              {isEdit ? " Update Product Details" : "Save  Product Details"}
                            </Button>
                          </div>
                        </div>


                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default ProductDetails;
