import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import EndpointService from "../../../utilities/constants/endpointService";
import HTTPService from "../../../service/HTTPService";
import { getNotifications } from "../../../store/notifications/notificationsAction";
import { message } from "antd";
import { cypherService } from "../../../utilities/cypher/cypherService";

export default function SideBar() {
  let reduxStore = useSelector((state: RootStateOrAny) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState<any>("All");
  const [totalNumber, setTotalNumber] = useState(0);
  const [sideBarList, setSideBarList] = useState<any>([{
    name: "All",
    value: "All"
  }, {
    name: "Unread",
    value: "Unread"
  }, {
    name: "Read",
    value: "Read"
  }, {
    name: "Starred",
    value: "Starred"
  }]);

  useEffect (()=>{
    let messageDatacnt = reduxStore.adminNotice && reduxStore.adminNotice.data && reduxStore.adminNotice.data;
    setTotalNumber(messageDatacnt);
  
  },[reduxStore])

  const getListItemClassName = (item: any) => {
    return item === activeItem ? 'active' : '';
  };

  const onClickRedirect = (el: any) => {
    debugger;

    if(el.name=="Unread"){
      cypherService.setLocalStorage("isgetUnread", true);
    }
    else{
      cypherService.setLocalStorage("isgetUnread", false);
    }
    navigate(`/notifications/${el.value}`)
    setActiveItem(el.name)


  }

  return <div className="notication-sidebar">
    <div className="school-section">
      {/* school */}


      <ul className="school-menu">
        {/* <li className="menu-active">School</li> */}

        {sideBarList &&
          sideBarList.map((item: any, index: number) => (
            <>
              {item && (
                <li className={getListItemClassName(item.name)} onClick={() => onClickRedirect(item)}>
                  {item.name}
                  {item&& item.name=="Unread"&&(
                    totalNumber !==0 ?  <span className="number">{totalNumber }</span> :  <span ></span> 
                    
                   )}
                </li >
              )}
            </>
          ))}
        {/* <li className={getListItemClassName('School')} onClick={() => onCancelClick("School")}>School <span>03</span></li>

        <li className={getListItemClassName('get')} onClick={() => onCancelClick("get")}>Value added Service</li>
        <li className={getListItemClassName('Campaign')} onClick={() => onCancelClick("Campaign")} >Ed-tech Expert <span>03</span></li>
        <li className={getListItemClassName('meetings')} onClick={() => onCancelClick("meetings")}>Customer Support</li> */}
        {/* <li className= {getListItemClassName('Support')}  onClick={() => onCancelClick("Support")}>Support</li> */}
      </ul>


      {/* admin */}


      {/* <ul className="school-menu">
      <li className="menu-active">Admin</li>
        <li className= {getListItemClassName('meeting')}  onClick={() => onCancelClick("meeting")} >Meeting <span>03</span></li>
        <li className= {getListItemClassName('Supports')}  onClick={() => onCancelClick("Supports")}>Support</li>
        <li  className= {getListItemClassName('vasRequest')}  onClick={() => onCancelClick("vasRequest")}>VAS Request <span>03</span></li>
      </ul> */}



      {/* <ul className="school-menu">
        <li className="menu-active">Notification</li>
        <li className={getListItemClassName('read')} onClick={() => onCancelClick("read")}>Read <span>03</span></li>
        <li className={getListItemClassName('unread')} onClick={() => onCancelClick("unread")}>Unread</li>
        <li className={getListItemClassName('starred')} onClick={() => onCancelClick("starred")}>Starred <span>03</span></li>
      </ul> */}
    </div>
  </div >

    ;
}
