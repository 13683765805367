import React, { useState, useEffect } from "react";
import { Input, Steps, Button, Spin, message, Dropdown, Space, notification, Card, Radio, Checkbox, Modal, Row, Col } from "antd";
import { Form, Select } from "formik-antd";
import { ErrorMessage, Formik } from "formik";
import { MdKeyboardBackspace } from "react-icons/md";
import TextArea from "antd/lib/input/TextArea";
import { CloudUploadOutlined, CalendarOutlined, CloseOutlined, GlobalOutlined } from "@ant-design/icons";
import Header from "../header/Header";
import { edtechExpertValidationSchema } from "./schema";
import { cypherService } from "../../utilities/cypher/cypherService";
import EndpointService from "../../utilities/constants/endpointService";
import HTTPService from "../../service/HTTPService";
import { useNavigate } from "react-router-dom";
import { daysList, media } from "./edTechTableHelper";
import environment from "../../environments/environment";
import AvailabilityModal from "./AvailabilityModal";
let imageBaseUrl = environment.imageBaseUrl;

export default function EdtechExpertDetails() {
    let availability: any = [...daysList]
    let isEdit = cypherService.getLocalStorage("isEditEdTech")
    let edTechExpertId = cypherService.getLocalStorage("edTechExpertId")
    let userId = cypherService.getLocalStorage("userId");
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [location, setLocation] = useState<any>([]);
    const [productCategory, setProductCategory] = useState<any>([])
    const [errorsMessage, setErrorsMessage] = useState<string>("")
    const [updatedList, setUpdatedList] = useState<any>([])
    const [dateErrors, setDateErrors] = useState("")
    const [days, setDays] = useState<any>(availability);
    const [editProfilePath,setEditProfilePath]=useState('');

    const [profileFiles, setProfileFiles] = useState<any>({
        imagePath: "",
        imageName: "",
        files: ""
    })

    const [initialValues, setInitialValues] = useState<any>({
        name: "",
        phoneNumber: "",
        email: "",
        yearOfExperience: "",
        professionalSummary: "",
        areaOfExperience: [],
        location: [],
        profile: "",
    });

    useEffect(() => {

        init()
    }, []);

    const init = () => {
        getLocation();

    }

    const getEdTechDetails = (productCategoryData: any) => {
        setLoader(true);
        let mapUrl = EndpointService.getSingleEdtechExpert + edTechExpertId
        HTTPService.get(mapUrl).then((response) => {
            console.log(response.data)
            let data = response.data
            setUpdatedList(data)
            if (data) {
                createEditInitialValues(data, productCategoryData)
            } else {
                setLoader(false);
            }

        }).catch((err) => {
            setLoader(false);
            message.error(err.response.data.status.message);
        });
    };

    const getLocation = () => {
        setLoader(true);
        let mapUrl = EndpointService.getCustomerAssessmentUrl + "CustomerAssessment/requiredToDisplayQuestionsFor?requiredToDisplayQuestionsFor=Admin";
        HTTPService.get(mapUrl).then((response) => {
            let data = response.data;
            data && data.forEach((item: any) => {
                if (item.code === "emirate") {
                    setLocation(item.questionaireOption);
                }
            });
            getProductCategory()
        }).catch((err) => {
            setLoader(false);
            message.error(err.response.data.status.message);
        });
    };

    const getProductCategory = () => {
        setLoader(true);
        let mapUrl = EndpointService.getProductCategoryOneOfTheAssessmentQuestionUrl;
        HTTPService.get(mapUrl).then((response) => {
            setLoader(false);
            let data = response.data;
            setProductCategory(data)
            if (isEdit) {
                setLoader(true);
                getEdTechDetails(data)
            }
        }).catch((err) => {
            setLoader(false);
            message.error(err.response.data.status.message);
        });
    };



    const deleteRequest = () => {
        setLoader(true);
        let mapUrl = EndpointService.edtechExpert + edTechExpertId + `?userId=${userId}`;
        HTTPService.delete(mapUrl)
            .then((response) => {
                const getStatus = response.status.success.toLowerCase();
                if (getStatus === "success") {
                    handleRedirect()
                    notification.success({
                        message: "",
                        description: response.status.message,
                        placement: "top",
                    });
                }
                setLoader(false);
            })
            .catch((error) => {
                notification.error({
                    message: "",
                    description: error,
                    placement: "top",
                });
                setLoader(false);
            });
    };

    const createEditInitialValues = (data: any, productCategoryData: any) => {
        const { name, email, phoneNumber, yearsOfExperience, professionalSummary, profilePath, edTechExpertProductCategory, edTechExpertQuestionaire, edTechExpertAvailability } = data
        let areaOfExperience: any = []
        let location: any = []
        edTechExpertProductCategory && edTechExpertProductCategory.forEach((item: any) => {
            productCategoryData && productCategoryData.forEach((el: any) => {
                if (el.parentProductCategoryId === item.productCategoryId) {
                    areaOfExperience.push(el.parentProductCategoryName)
                }
            })
        })

        edTechExpertQuestionaire && edTechExpertQuestionaire.forEach((item: any) => {
            location.push(item.otherText)
        })

        setInitialValues({
            ...initialValues,
            name,
            phoneNumber,
            email,
            yearOfExperience: yearsOfExperience,
            professionalSummary,
            profile: profilePath,
            location,
            areaOfExperience,

        })
        setProfileFiles({
            ...profileFiles,
            imagePath: imageBaseUrl + profilePath

        })
        setEditProfilePath(profilePath);
        if (edTechExpertAvailability && edTechExpertAvailability.length > 0) {
            let result: any = []
            edTechExpertAvailability.forEach((item: any) => {
                let object = {
                    "name": item.weekDay,
                    "startTiming": item.startTiming,
                    "endTiming": item.endTiming,
                    "active": true,
                    "isEdit": true,
                    "edTechExpertAvailabilityId": item.edTechExpertAvailabilityId,
                }
                result.push(object)
            })

            availability && availability.forEach((el: any) => {
                let isValid = result.some((item: any) => el.name === item.name ? true : false)
                if (!isValid) {
                    result.push(el)
                }

            })
            setDays(result)
        }
        setLoader(false);
    }


    const checkEditId = (el: any) => {
        let returnValue = null
        updatedList && updatedList.edTechExpertProductCategory.forEach((e: any) => {
            if (e.productCategoryId === el) {
                returnValue = e.edTechExpertProductCategoryId
            }
        })
        return returnValue
    }

    const payloadProductCategory = (el: any) => {
        let cacheArray: any = []
        if (isEdit) {
            el && el.forEach((item: any) => {
                productCategory.map((data: any) => {
                    if (data.parentProductCategoryName === item) {
                        let editId = checkEditId(data.parentProductCategoryId)
                        if (editId) {
                            let cacheObject = {
                                "productCategoryId": data.parentProductCategoryId,
                                // "edTechExpertProductCategoryId": editId
                            }
                            cacheArray.push(cacheObject)
                        } else {
                            let cacheObject = {
                                "productCategoryId": data.parentProductCategoryId
                            }
                            cacheArray.push(cacheObject)
                        }

                    }
                })

            })
        } else {
            el && el.forEach((item: any) => {
                productCategory.map((data: any) => {
                    if (data.parentProductCategoryName === item) {
                        let cacheObject = {
                            "productCategoryId": data.parentProductCategoryId
                        }
                        cacheArray.push(cacheObject)
                    }
                })

            })
        }
        return cacheArray
    }



    const edTechExpertEdit = (name: string) => {
        let returnValue = null
        updatedList && updatedList.edTechExpertQuestionaire.forEach((item: any) => {
            if (item.otherText === name) {
                returnValue = item.edTechExpertQuestionaireId
            }
        })
        return returnValue
    } 

    const edTechExpertQuestionaire = (el: any) => {
        let cacheArray: any = []
        if (isEdit) {
            el && el.forEach((name: any) => {
                location && location.forEach((item: any) => {
                    if (item.option === name) {
                        let questionaireId = edTechExpertEdit(name)
                        if (questionaireId) {
                            let cacheObject = {
                                "otherText": name,
                                "questionaireOptionId": item.questionaireOptionId,
                                // "edTechExpertQuestionaireId": questionaireId
                            }
                            cacheArray.push(cacheObject)
                        } else {
                            let cacheObject = {
                                "otherText": name,
                                "questionaireOptionId": item.questionaireOptionId,
                            }
                            cacheArray.push(cacheObject)
                        }
                    }
                })
            })
        } else {
            el && el.forEach((name: any) => {
                location && location.forEach((item: any) => {
                    if (item.option === name) {
                        let cacheObject = {
                            "otherText": name,
                            "questionaireOptionId": item.questionaireOptionId
                        }
                        cacheArray.push(cacheObject)
                    }
                })
            })
        }

        return cacheArray
    }
    const edTechExpertAvailability = (el: any) => {
        let arrayValue: any = []

        el && el.forEach((item: any) => {
            if (!item.error && item.active) {
                let obj = {
                    "weekDay": item.name,
                    "startTiming": item.startTiming,
                    "endTiming": item.endTiming,
                    // ...(isEdit && { edTechExpertAvailabilityId: item.edTechExpertAvailabilityId })
                }
                arrayValue.push(obj)
            }
        })

        return arrayValue
    }
    const onSubmit = (el: any) => {
        let formData = null
        if (profileFiles.files) {
            formData = new FormData();
            formData.append("profileMultipartFile", profileFiles.files);
        } else {
            formData = {}
        }
        let payload = {
            "edTechExpertProductCategory": [...payloadProductCategory(el.areaOfExperience)],
            "edTechExpertQuestionaire": [...edTechExpertQuestionaire(el.location)],
            "edTechExpertAvailability": [...edTechExpertAvailability(days)],
            "email": el.email,
            "name": el.name,
            "phoneNumber": el.phoneNumber,
            "professionalSummary": el.professionalSummary,
            "yearsOfExperience": el.yearOfExperience,
            "profilePath":editProfilePath,
            "timeZone": "GST(GMT+4:00)",
            ...(isEdit && { "edTechExpertId": edTechExpertId })

        }
        let encodePayload = encodeURIComponent(JSON.stringify(payload))
        setLoader(true);
        let addUrl = EndpointService.createEdtechExpert + `edTechExpertDto=${encodePayload}` + "&userId=" + userId
        let updateUrl = EndpointService.edtechExpert + `${edTechExpertId}?edTechExpertDto=${encodePayload}` + "&userId=" + userId
        let mapUrl = isEdit ? updateUrl : addUrl;
        let apiMethod = isEdit ? "put" : "post";

        HTTPService.customApiMethod(mapUrl, formData, apiMethod).then(response => {
            setLoader(false);
            if (response.status.success === 'Success') {
                message.success(response.status.message);
                handleRedirect()
            }
            else if (response.status.success === 'Fail') {
                message.error(response.status.message);
                handleRedirect()
            }
        }).catch((error) => {
            setLoader(false);
                if (error.response.status.success === 'Fail') {
                    message.error(error.response.status.message);
                }
            })


    };
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        let error = ""
        let result = days && days.map((item: any) => {
            if (item.active) {
                if (item.startTiming === "00:00" || item.endTiming === "24:00") {
                    error = "Please select start date and end date"
                    item["error"] = "Please select start date and end date"
                }
            }
            return item
        })
        if (error) {
            setDays(result)
        } else {
            setDateErrors("")
            setIsModalOpen(false);
        }

    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleMediaChange = media(setErrorsMessage, setProfileFiles);
    const handleRemoveImage = (setFieldValue: any) => {
        setFieldValue("profile", "")
        setProfileFiles({
            imagePath: "",
            imageName: "",
            files: ""
        })
    }
    const handleRedirect = () => {
        navigate("/viewdata")
        cypherService.setSessionStorage('detailsTab', '3');
    }


    return (
        <div className="school-details">
            {loader ? <Spin /> : null}
            <Header />
            <div className="">
                <div className="page-header">
                    <div className="page-heading">
                        <span className="back" onClick={handleRedirect}>
                            <MdKeyboardBackspace />
                        </span>
                        <h3>Edtech Expert Details</h3>
                    </div>
                    {
                        isEdit && <div><Button className="btn-secondary not-rounded" onClick={deleteRequest}>Delete</Button></div>
                    }

                </div>

                <div className="schoolDetails-main edtechDetails ">
                    <Formik initialValues={initialValues} validationSchema={edtechExpertValidationSchema}
                    onSubmit={(values, { resetForm }) => onSubmit(values)} enableReinitialize={true}>
                        {({ handleChange, errors, isSubmitting, values, setFieldValue }) => {

                            return (
                                <Form>
                                    <div className="edtechDetails-body">
                                        <div className="grid grid-cols-4 gap-4">
                                            <Card bordered={false} className="mt-5">
                                                <div className="answer-field">
                                                    <label htmlFor="">Name</label>
                                                    <Input placeholder="Enter the Name" name="name" value={values.name} onChange={(e) => handleChange(e)} />
                                                    <div className="error">
                                                        <ErrorMessage name="name" />
                                                    </div>
                                                </div>
                                            </Card>
                                            <Card bordered={false} className="mt-5">
                                                <div className="answer-field">
                                                    <label htmlFor="">Phone Number</label>
                                                    <Input placeholder="Enter the Phone Number" name="phoneNumber" value={values.phoneNumber} onChange={(e) => handleChange(e)} />
                                                    <div className="error">
                                                        <ErrorMessage name="phoneNumber" />
                                                    </div>
                                                </div>
                                            </Card>
                                            <Card bordered={false} className="mt-5">
                                                <div className="answer-field">
                                                    <label htmlFor="">Email ID</label>
                                                    <Input placeholder="Enter the Email ID" name="email" value={values.email} onChange={(e) => handleChange(e)} />
                                                    <div className="error">
                                                        <ErrorMessage name="email" />
                                                    </div>
                                                </div>
                                            </Card>

                                            <Card bordered={false} className="mt-5">
                                                <div className="answer-field">
                                                    <label htmlFor="">Experience</label>
                                                    <Input placeholder="Enter the Years of Experience"
                                                        name="yearOfExperience" value={values.yearOfExperience} onChange={(e) => handleChange(e)} />
                                                    <div className="error">
                                                        <ErrorMessage name="yearOfExperience" />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="grid grid-cols-2  gap-4 ">
                                            <div className="row-span-2">
                                                <Card bordered={false} className="mt-5 ">
                                                    <label htmlFor="">Professional Summary</label>
                                                    <div className="answer-field">
                                                        <div className="w-full">

                                                            <TextArea placeholder="Enter the Professional Summary" name="professionalSummary" value={values.professionalSummary} onChange={(e) => handleChange(e)} />
                                                        </div>
                                                        <div className="error">
                                                            <ErrorMessage name="professionalSummary" />
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card bordered={false} className="mt-0 ">
                                                    <label htmlFor="">Set Availablity</label>
                                                    <div className="answer-field answer-text">
                                                        <div className="w-full">
                                                            <span className='calender-icon'>   <CalendarOutlined /></span>

                                                            <TextArea disabled placeholder="Please set Edtech Expert availability here" />
                                                            <p className="view-calender" onClick={showModal}>
                                                                View Calender
                                                            </p>
                                                        </div>
                                                        <div className="error">
                                                            <ErrorMessage name="setAvailability" />
                                                        </div>
                                                    </div>
                                                </Card >
                                            </div >
                                            <div className="row-span-2 pt-12">
                                                <div className="answer-field relative">
                                                    <label htmlFor="">Areas of Expertise</label>
                                                    <Select name="areaOfExperience" value={values.areaOfExperience} allowClear showSearch mode="multiple" placeholder="Enter the Area of Expertise" className="pt-5" >

                                                        {productCategory &&
                                                            productCategory.map((data: any) => {
                                                                return <Select.Option value={data.parentProductCategoryName}>{data.parentProductCategoryName}</Select.Option>;
                                                            })}
                                                    </Select>
                                                    <div className="error">
                                                        <ErrorMessage name="areaOfExperience" />
                                                    </div>
                                                </div>
                                                <div className="answer-field relative">
                                                    <label htmlFor="">Location</label>
                                                    <Select name="location" mode="multiple" showSearch placeholder="Enter the Location"
                                                        value={values.location}
                                                        allowClear className="pt-12">
                                                        {location &&
                                                            location.map((data: any) => {
                                                                return <Select.Option value={data.option}>{data.option}</Select.Option>;
                                                            })}

                                                    </Select>
                                                    <div className="error">
                                                        <ErrorMessage name="location" />
                                                    </div>
                                                </div >
                                                <Card bordered={false}>
                                                    <div className="answer-field ">
                                                        <div className="dropFile">
                                                            <div>
                                                                {
                                                                    profileFiles.imagePath ? (<div className="uploadedImage">
                                                                        <img src={profileFiles.imagePath} alt="profile" />
                                                                        <span onClick={() => handleRemoveImage(setFieldValue)}>
                                                                            <CloseOutlined />
                                                                        </span>
                                                                    </div>) : (<label className="filelabel">
                                                                        <div className="flex">
                                                                            <div className="upload-icon">
                                                                                <CloudUploadOutlined className="text-green" />
                                                                            </div>
                                                                            <div className="ml-10 upload-cont">
                                                                                <p className="browse font-sans">
                                                                                    Click to <span className="text-green">browse</span>
                                                                                </p>
                                                                                <p className="file-size font-sans">
                                                                                    Profile photo<span className="inline-block mx-2">|</span> max 3MB
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <input className="FileUpload1" id="FileInput1" type="file" name="profile" value={values.profile} onChange={(e) => handleMediaChange(e, handleChange)} />
                                                                    </label >)
                                                                }

                                                            </div >
                                                        </div >
                                                        <div className="error">
                                                            {errorsMessage}
                                                            <ErrorMessage name="profile" />
                                                        </div>
                                                    </div >
                                                    {/* <div className="profile-pic-view">
                                                            <p className='professional-summery'>Profile Photo</p>
                                                            <img src="" alt="" />

                                                        </div> */}
                                                </Card >
                                            </div >
                                        </div >
                                    </div >

                                    <div className="btn-group text-right pt-5">
                                        <Button className="btn-primary m-0" htmlType="submit">
                                            {isEdit ? " Update and Save" : "Save"}
                                        </Button>
                                    </div>
                                </Form >
                            );
                        }}
                    </Formik >
                </div >
            </div >
            <Modal title="Enter your availability" className="availabilty-modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <AvailabilityModal setDays={setDays} days={days} handleCancel={handleCancel} dateErrors={dateErrors} handleOk={handleOk} />
            </Modal>
        </div >
    );
}


