import { Table, Input, Spin, notification, Button, Tooltip, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { CheckOutlined, CheckSquareOutlined, CloseOutlined, CloseSquareOutlined, VideoCameraAddOutlined } from '@ant-design/icons'
import EndpointService from '../../utilities/constants/endpointService';
import HTTPService from '../../service/HTTPService';
import { cypherService } from '../../utilities/cypher/cypherService';
import moment from 'moment';
import { checkTableNull } from '../viewSchool/helper';
import ConfirmModal from './ConfirmModal';
import { Link } from 'react-router-dom';



const UpcomingMeetings = (props: any) => {
    let userId = cypherService.getLocalStorage("userId");
    const rejectReasonList = [
        {
            value: 'Scheduled another meeting at the same time',
        },
        {
            value: 'Waiting for the tech team update',
        },
        {
            value: 'Attending high-priority case',
        },
        {
            value: 'Team on holiday',
        },
        {
            value: "Gathering resources on progress"
        },
        {
            value: 'Others',
        }
    ]
    const [upcomingMeetingList, setUpcomingMeetingList] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [start, setStart] = useState<any>(0);
    const [limit] = useState<any>(10);
    const [searchText, setSearchText] = useState<string>('');
    const [totalData, setTotalData] = useState<any>(0);
    const [checkedReason, setCheckedReason] = useState<any>("");
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [rejectReason, setRejectReason] = useState<string>('');
    const [notificationId, setNotificationId] = useState<any>(null)
    const [rejectPayload, setRejectPayload] = useState<any>(null)
    let { currentTab } = props;

    useEffect(() => {
        getUpcomingMeetingList(start, limit, searchText);
    }, [currentTab])

    const getUpcomingMeetingList = (startValue: any, limitValue: any, searchTextValue: string) => {
        if (currentTab == '1') {
            setLoader(true);
            let mapUrl: string = EndpointService.getMeetingListUrl + `?limit=${limitValue}&meetingRecordFlag=Upcoming&start=${startValue}`;
            if (searchText != '') {
                mapUrl = mapUrl + `&searchBy=${searchTextValue}`;
            }
            HTTPService.get(mapUrl, {}).then((response) => {
                debugger;
                if (response) {
                    let apiResponse: any = response.data 
                    let meetingData = apiResponse && apiResponse[0]
                    // apiResponse.forEach((item: any) => {
                        // item.edTechExpertMeetingScheduleDtoList.forEach((item: any) => {
                            // setNotificationId(item.notificationId);
                        // })
                    // })

                    // const { customerMeetingScheduleRecordsDtoList, edTechExpertMeetingScheduleDtoList } = meetingData && meetingData
                    let tempUpcomingMeetingList: any = [];
                    // apiResponse.forEach((item: any) => {
                    //     let meetingObject = {
                    //         name: item.customer.name,
                    //         nameList: [item.serviceProviderName, "SolvEd Admin"],
                    //         organization: item.organizationName,
                    //         dateTime: moment(item.scheduleDate).format('LLL'),
                    //         subject: item.scheduleNote,
                    //         meetingLink: item.meetingUrl,
                    //         action: ""
                    //     }
                    //     tempUpcomingMeetingList.push(meetingObject)
                    // })
                    
                   apiResponse.map((item: any) => {
                    setNotificationId(item.notificationId);

                        let user: string = ""
                        let id: any = ""
                        let typeName: string = ""
                        let isActive: boolean = false
                        if (item.customer) {
                            user = item.customer.name
                            id = item.customer.customerId
                            typeName = "customer"
                            isActive = true
                        } else {
                            user = item.serviceProvider.companyName
                            id = item.serviceProvider.serviceProviderId
                            typeName = "serviceProvider"
                            isActive = false
                        }
                        let meetingObject = {
                            name: user,
                            organization: item.organizationName,
                            dateTime: moment(item.scheduleDate).format('LLL'),
                            nameList: [item.edTechExpert.name, "SolvEd Admin"],
                            subject: item.scheduleNote,
                            meetingLink: item.meetingUrl,
                            action: id,
                            status: item.status,
                            type: typeName
                        }
                        tempUpcomingMeetingList.push(meetingObject)
                    })

                    setUpcomingMeetingList([...tempUpcomingMeetingList]);
                    setTotalData(response.totalResults ? response.totalResults : 0)
                    setLoader(false);
                    console.log([...tempUpcomingMeetingList])

                }
                else {
                    
                    setLoader(false);
                    // notification.error({
                    //     message: '',
                    //     description: response.status.message,
                    //     placement: 'top'
                    // })
                }
            })
                .catch(error => {
                    setLoader(false);
                    notification.error({
                        message: '',
                        description:"error",
                        placement: 'top'
                    })
                })
        }
    }

    const changeText = (event: any) => {
        setSearchText(event.target.value);
        getUpcomingMeetingList(start, limit, event.target.value);
    }
    const pageChange = (page: any,pageSizeOptions:any) => {
        debugger;
        let startValue: number = (page * pageSizeOptions ) - pageSizeOptions;
        setStart(startValue);
        // getPreviousMeetingList(startValue, pageSizeOptions, searchText);
        getUpcomingMeetingList(startValue, limit, searchText);
    }
    // const pageChange = (page: any) => {
    //     let startValue: number = (page * 10) - 10;
    //     setStart(startValue);
    // }
    const handleListCompanyName = (el: any) => {
        return <span className='companyName'>{el}</span>
    }
    const handleOpenModal = () => {
        setModalShow(true)
    }
    const cancelAction = () => {
        setModalShow(false)
    }


    const approvedRejectedApiRequest = (payloadUrl: any) => {
        let mapUrl: string = EndpointService.createMeetingApproved + payloadUrl
        debugger
        setLoader(true);
        HTTPService.put(mapUrl, {}).then(response => {
            if (response.status.success.toLowerCase() === 'success') {
                notification.success({
                    message: '',
                    description: response.status.message,
                    placement: 'top'
                })
                cancelAction()
                setLoader(false);
                getUpcomingMeetingList(start, limit, searchText);
            }
            if (response.status.success.toLowerCase() === 'fail') {
                setLoader(false);
                cancelAction()
                notification.error({
                    message: '',
                    description: response.status.message,
                    placement: 'top'
                })
            }
        }).catch(err => {
            setLoader(false);
            cancelAction()
            notification.error({
                message: '',
                description: err.response.data.status.message,
                placement: 'top'
            })
        })

    }

    const addApprovedRequest = (item: any, record: any) => {
        debugger
        let payloadUrl: any = null
        if (record.type !== "serviceProvider") {
            payloadUrl = `?customerId=${item}&notificationId=${notificationId}&status=Approved&userId=${userId} `

        } else {
            payloadUrl = `?serviceProviderId=${item}&notificationId=${notificationId}&status=Approved&userId=${userId} `
        }
        approvedRejectedApiRequest(payloadUrl)
        console.log(payloadUrl)
    }
    const rejectApprovedRequest = (item: any, record: any) => {
        setRejectPayload(record)
        handleOpenModal()
    }

    const onSubmitReject = () => {
        const record = rejectPayload
        let payloadUrl: any = null

        let rejectNote: string = ""
        if (checkedReason === "Others") {
            rejectNote = rejectReason
        } else {
            rejectNote = checkedReason
        }
        debugger
        if (record.type !== "serviceProvider") {
            payloadUrl = `?customerId=${record.action}&notificationId=${notificationId}&status=Rejected&userId=${userId}&rejectNote=${rejectNote}`

        } else {
            payloadUrl = `?serviceProviderId=${record.action}&notificationId=${notificationId}&status=Rejected&userId=${userId}&rejectNote=${rejectNote}`
        }
        approvedRejectedApiRequest(payloadUrl)
    }

    const handleNewLink = () => {

    }
    const columns: any = [
        {
            title: 'Name',
            width: 280,
            dataIndex: 'name',
            key: 'name',
            render: (item: any, data: any) => {
                return (<>
                    <div className="td-name flex">
                        <div> {item}</div>
                <div className="highlighted"> 
               <Tooltip placement="top" title= {data.nameList && data.nameList.toString()}>
                                and  2  Others
                            </Tooltip>

                            {/* {data.nameList && data.nameList.map ((el:any)=> (<>{el}</>))} */}

                        </div>
           </div> 
                </>)
            },
        },
        {
            title: 'Organization',
            width: 300,
            dataIndex: 'organization',
            key: 'organization',
            render: (item: any, data: any) => { return (<>{item && item.map((el: any) => (handleListCompanyName(el)))}</>) }
        },
        {
            title: 'Date & Time',
            width: 180,
            dataIndex: 'dateTime',
            key: 'dateTime',
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            width: 300,
        },

        {
            title: 'Link',
            dataIndex: 'meetingLink',
            key: 'meetingLink',
            width: 100,
            // return: (item: any) => { item && window.open(item, "_blank") }

            render: (item: any, data: any) => {
                return (
                    <a href={item} className="icon-btn-group float-left" target="_blank">
                        <Tooltip placement="top" title={`${item}`}>
                            <Button type="primary" className="table-btn-3"><VideoCameraAddOutlined /></Button>
                        </Tooltip>
                    </a>
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            render: (item: any, record: any) => {
                return (
                    <> {
                        record.status === "Pending" ? <div className='table-action flex justify-evenly'>
                            {
                                item && <> <span className='icon' onClick={() => addApprovedRequest(item, record)}><CheckOutlined /></span>
                                    <span className='icon' onClick={() => rejectApprovedRequest(item, record)}> <CloseOutlined /></span></>
                            }

                        </div>:  <div className='table-action flex justify-evenly'>
                            {
                                <> <button   disabled={ record.status !== "Pending" ? true:false }     style={{ cursor: record.status !== "Pending"  ? 'not-allowed' : 'pointer' }} className='icon' onClick={() => addApprovedRequest(item, record)}><CheckOutlined /></button>
                                    <button  disabled={ record.status !== "Pending" ? true:false }     style={{ cursor: record.status !== "Pending"  ? 'not-allowed' : 'pointer' }}  className='icon' onClick={() => rejectApprovedRequest(item, record)}> <CloseOutlined /></button></>
                            }

                        </div>
                    } </>

                )
            },
        },
    ];
    return (
        <div className="view-table">
            {loader ? <Spin /> : null}

            {/* <pre className='text-white'>{JSON.stringify(upcomingMeetingList, null, 1)}</pre> */}
            <Table columns={columns} dataSource={upcomingMeetingList} scroll={{ y: 'calc(100vh - 281px)', x: 0 }} pagination={{ total: totalData, onChange: pageChange ,  defaultPageSize:10,
      defaultCurrent:1,pageSizeOptions:[10, 20, 50, 100]}} />
    
            <Modal
                centered
                visible={modalShow}
                onOk={cancelAction}
                onCancel={cancelAction}
                footer={null}
                className="product-summary-modal free-demo-modal reject-modal"
            >
                <ConfirmModal rejectReasonList={rejectReasonList} setCheckedReason={setCheckedReason} checkedReason={checkedReason} rejectReason={rejectReason} cancelAction={cancelAction} setRejectReason={setRejectReason} onSubmitReject={onSubmitReject} />
            </Modal>
        </div>
    );
}

export default UpcomingMeetings;
